.hover-menu {
	position: relative;
	user-select: none;
	counter-reset: itemcounter;
}

@screen <md {
	.hover-menu {
		@apply block;
	}
}

@screen >=md {
	.hover-menu {
		display: grid;
	}
}

.hover-menu__item {
	justify-content: start;
	position: relative;
	display: grid;
	width: 100%;

	&::before {
		text-align: right;
		grid-area: counter;
		/* align-self: start; */
		justify-self: end;
		counter-increment: itemcounter;
		content: counters(itemcounter, '.', decimal-leading-zero);
		color: var(--gold);
		opacity: 0;
		line-height: 1;
		padding-right: 0.5rem;
		display: grid;
		place-items: center;
		font-size: clamp(0.1rem, 1vw, 1rem);
		transform: translateX(1rem);
		transition: transform 0.3s, opacity 0.3s;
	}

	&:hover {
		&::before {
			color: var(--white-gold);
			opacity: 1;
			transform: translateX(0);
		}
	}
}

@screen <md {
	.hover-menu__item {
		@apply block;
		padding: 0.6rem 0;
		/* grid-template-areas: 'counter text';
		grid-template-columns: 2rem auto; */
	}
}

@screen >=md {
	.hover-menu__item {
		padding: 0.5rem 1rem;
		grid-template-rows: auto;
		grid-template-areas:
			'. sub .'
			'counter text .';
		grid-template-columns: 4rem auto 4rem;
	}
}

.hover-menu__item-text {
	grid-area: text;
	position: relative;
	cursor: pointer;
	display: block;
	overflow: hidden;
	line-height: 1;
	height: 100%;
}

.hover-menu__item-textinner {
	@apply relative block w-full h-full action-menu-ja;

	font-weight: 400;
}

@screen <md {
	.hover-menu__item-textinner {
		line-height: 1.5;
	}
}

@screen >=md {
	.hover-menu__item-textinner {
		line-height: 1.2;
	}
}

.hover-menu__item-sub {
	@apply relative w-full ml-auto opacity-0 title3-en uppercase;

	grid-area: sub;
	font-weight: 400;
	/* white-space: nowrap; */
	line-height: 1;
	padding: 0 0 0.5rem 0;
	transform: translateX(-1rem);
	transition: transform 0.3s, opacity 0.3s;

	.hover-menu__item:hover & {
		@apply opacity-100;

		transform: translateX(0);
	}
}

@screen <md {
	.hover-menu__item-sub {
		display: none;
	}
}

@screen >=md {
	.hover-menu__item-sub {
		display: block;
	}
}

.hover-menu__media {
}

.hover-reveal {
	@apply absolute top-0 left-0 pointer-events-none opacity-0;

	z-index: -1;
	will-change: transform, filter;
}

@screen <md {
	.hover-reveal {
		width: 300px;
		height: 400px;
	}
}

@screen >=md {
	.hover-reveal {
		width: 300px;
		height: 400px;
	}
}

.hover-reveal__inner {
	overflow: hidden;
}

.hover-reveal__inner,
.hover-reveal__img {
	width: 100%;
	height: 100%;
	position: relative;
}

.hover-reveal__img {
	background-size: cover;
	background-position: 50% 50%;
}
