.hero {
	@apply relative z-hero h-screen;

	background: var(--blue-grad);
}

@screen sp-land {
	.hero {
		@apply flex justify-end;
	}
}

@screen <md {
	.hero {
		height: calc(var(--vh, 1vh) * 100);
	}
}

@screen >=md {
	.hero {
		@apply flex justify-between;
	}
}

.hero__slider {
	@apply relative z-bg w-full;
}

/* @screen <md {
	.hero__slider {
		height: 77vh;
	}
} */

.hero__medias {
	&::after {
		@apply absolute w-full h-full top-0 left-0 pointer-events-none;

		z-index: 2;
		content: '';
		background: var(--blue-grad-overlay);
	}
}

.hero__tagline {
	@apply absolute z-content;

	color: var(--white);
	transform: translateY(-20%);
}

@screen <md {
	.hero__tagline {
		@apply bottom-0 px-4;
	}
}

@screen >=md {
	.hero__tagline {
		@apply right-14 bottom-0;
	}
}

/*
.hero__tag-line {
	@apply relative z-content flex items-center h-full;
}

@screen <sm {
	.hero__tag-line {
		@apply pl-6;

		width: 150px;
	}
}

@screen sm {
	.hero__tag-line {
		@apply pl-6 w-1/4;
	}
}

@screen md {
	.hero__tag-line {
		@apply pl-6 w-1/4;
	}
}

@screen >=lg {
	.hero__tag-line {
		@apply w-1/3 pl-20;
	}
} */

.hero__title {
	@apply relative z-content;

	.en {
		@apply hero-title-en uppercase;

		font-family: var(--font-serif-ja), serif;
		line-height: 1.1;
	}

	.ja {
		@apply block hero-title-ja mt-4;

		font-family: var(--font-sans-jp), sans-serif;
		font-weight: 500;
		line-height: 1.4;
	}
}

/* @screen <md {
	.hero__title {
		.ja {
			br {
				@apply hidden;
			}
		}
	}
} */
