/*--------------------
  Header
--------------------*/
.header {
	@apply fixed w-full top-0 left-1/2 z-header;

	background-color: var(--black);
	transform: translate(-50%, 0);
	transition: transform 0.3s var(--transition_03);

	&.is-active {
		transform: translate(-50%, -135px);
		transition: transform 0.3s var(--transition_03);
	}
}

@screen <lg {
	.header {
	}
}

@screen >=lg {
	.header {
		@apply flex flex-wrap justify-between;
	}
}
/*--------------------
  Logo
--------------------*/
.logo {
	@apply absolute top-0 left-0 z-logo;

	order: 2;
	/* transition: transform 0.3s var(--transition_03);

	.header.is-active & {
		transform: translateX(0);
	} */
}

@screen <md {
	.logo {
	}
}

@screen md {
	.logo {
		@apply ml-6;
	}
}

@screen >=lg {
	.logo {
		@apply ml-20;
	}
}

.logo__link {
	@apply flex items-center justify-center;

	background: var(--blue-grad);

	&::after {
		@apply absolute block top-0 left-0 w-full h-full;

		z-index: -1;
		content: '';
		background-color: rgb(0, 0, 0);
		filter: blur(30px);
		transform: translateY(10px) scale(0.85);
		mix-blend-mode: multiply;
		transition: opacity 0.3s var(--transition_03);

		.header.is-active & {
			@apply opacity-0;
		}
	}

	transform-origin: top;
	transition: min-height 0.3s var(--transition_03);

	.header.is-active & {
		min-height: 135px;
	}
}

@screen <md {
	.logo__link {
		width: 150px;
		min-height: 150px;
	}

	.logo__image {
		width: 114px;
		height: 34px;
	}
}

@screen >=md {
	.logo__link {
		width: 230px;
		min-height: 230px;
	}

	.logo__image {
		width: 177px;
		height: 53px;
	}
}

@screen sp-land {
	.logo__link {
		width: 150px;
		min-height: 150px;
	}

	.logo__image {
		width: 114px;
		height: 34px;
	}
}

.logo__image {
	@apply object-contain;
}

/*--------------------
  Header links
--------------------*/

.header__links {
	@apply relative flex justify-end;

	height: 55px;
}

/* @screen md {
	.header__links {
		@apply pr-4;
	}
} */

@screen <lg {
	.header__links {
		@apply w-full;
		/* width: calc(100% - 55px); */
	}
}

@screen >=lg {
	.header__links {
		@apply flex items-center w-full pr-4;
	}
}

.header__links__bg {
	@apply absolute top-0 left-0 h-full;

	background: var(--red-grad);
}

@screen <lg {
	.header__links__bg {
		@apply w-full;
	}
}

@screen >=lg {
	.header__links__bg {
		@apply w-1/3;
	}
}

.header__links__menu {
	@apply flex;
}

@screen <lg {
	.header__links__menu {
		@apply hidden;
	}
}

@screen >=lg {
	.header__links__menu {
		@apply ml-auto mr-3;
	}
}

.links__link {
	@apply relative links-txt;

	transition: opacity 0.3s var(--transition_03);
	-webkit-tap-highlight-color: transparent;

	.header__links & {
		line-height: 55px;
		color: var(--font-light-color);
	}

	&:hover {
		@apply opacity-80;
	}

	&::after {
		@apply absolute block icon-bg;
	}

	&.access {
		&::after {
			@apply icon-access;
		}
	}

	&.english {
		&::after {
			@apply icon-english;
		}
	}

	&.contact {
		&::after {
			@apply icon-contact;
		}
	}

	&.magazine {
		&::after {
			@apply icon-magazine;
		}
	}

	&.search {
		@apply block cursor-pointer;

		line-height: 55px;

		&::after {
			@apply icon-search;
		}
	}
}

@screen >=sm {
	.links__link {
		.header__links & {
			@apply pr-4;

			padding-left: 36px;
		}
	}
}

@screen <lg {
	.links__link {
		.header__links & {
			&::after {
				@apply left-1/2;

				transform: translateX(-50%);
			}

			/* &.access, */
			&.english,
			&.contact,
			&.magazine {
				@apply hidden;
			}
		}
	}
}

.header__sns {
	@apply relative flex justify-center px-3;

	.icon-fb {
		fill: var(--white-gold);
	}

	.icon-tw {
		fill: var(--white-gold);
	}

	.icon-yt {
		fill: var(--white-gold);
	}

	.sns__icon {
		transform: scale(0.8);
	}

	.sns__item__link {
		width: 48px;
		height: 55px;
	}
}

@screen >=lg {
	.header__sns {
		&::before {
			@apply block absolute top-1/2 left-0 opacity-30;

			width: 1px;
			height: 18px;
			content: '';
			background-color: var(--white-gold);
			transform: translateY(-50%);
		}
	}
}

@screen <lg {
	.header__sns {
		margin-right: 55px;
	}
}

/*--------------------
  Navigation
--------------------*/

.header__nav {
	@apply relative z-nav ml-auto w-full;

	height: 80px;
	background-color: var(--base-white-color);
}

@screen <lg {
	.header__nav {
		@apply hidden;
	}
}

@screen >=lg {
	.header__nav {
		@apply flex justify-end box-border;

		order: 3;
	}

	.header__nav__menu {
		@apply z-nav box-border flex items-center justify-end h-full mr-10 opacity-100 visible;
	}

	.nav__menu__item {
		@apply h-full;

		/* transition: opacity 0.3s var(--transition_03),
			transform 0.3s var(--transition_03);

		.header.is-active & {
			@apply opacity-0;

			transform: translateY(-10px);
		} */
	}

	.nav__menu__link,
	.nav__menu__btn {
		@apply box-border flex items-center h-full cursor-pointer;

		transition: opacity 0.48s var(--transition_03),
			visibility 0.3s var(--transition_03);

		.is-hide & {
			@apply opacity-0 invisible;
		}
	}

	.nav__menu__link {
		@apply relative;

		padding: 0 20px;
		color: var(--black);

		&.is-current {
			color: var(--font-red-color);
		}

		&[data-pulldown] {
			&::after {
				border: solid var(--cardinal-red);
				content: '';
				transition: transform 0.3s var(--transition_03);

				@apply block absolute	top-1/2 icon-arrow icon-arrow-down;
			}

			&.is-hovering {
				&::after {
					@apply icon-arrow-up;
				}
			}
		}
	}

	.nav__menu__btn {
		@apply px-3;
	}

	.nav__menu__link__wrap {
		@apply relative;
	}

	.nav__menu__link__text {
		@apply flex w-full pl-2 overflow-hidden nav-txt;

		font-weight: 400;
		/* min-width: 100px; */

		.is-ie & {
			width: 120px;
			height: 30px;
		}
	}
}

.nav__menu__link__text--en,
.nav__menu__link__text--ja {
	@apply inline-block relative;
}

.nav__menu__link__text--en {
	/* @include header-nav-en; */

	@apply nav-txt uppercase opacity-100 tracking-wider;

	transition: 0.3s;
	transform-origin: bottom;
	/* transition-delay: calc(0.02s * var(--index)); */

	/* Hover Rotation */
	.nav__menu__link:hover & {
		@apply opacity-0;
		transform: translate(0, -100%) rotateX(-90deg);
	}
}

.nav__menu__link__text--ja {
	/* @include header-nav-en; */

	@apply nav-txt opacity-0;

	transition: 0.3s;
	transform-origin: top;
	transform: translate(0, 100%) rotateX(-90deg);
	/* transition-delay: calc(0.02s * var(--index)); */

	/* Hover Rotation */
	.nav__menu__link:hover & {
		@apply opacity-100;
		transform: translate(0, 0) rotateX(0deg);
	}
}

/*--------------------
  SP Nav Trigger
--------------------*/
@screen <lg {
	.nav__trigger {
		@apply fixed top-0 right-0 z-modalBtn;

		transform: translateY(0);
	}

	.nav__trigger__btn {
		@apply flex items-center justify-center cursor-pointer;

		width: 55px;
		height: 55px;
		background-color: var(--black);
		transition: transform 0.3s var(--transition_03);

		.nav__trigger.is-active & {
			/* @apply opacity-0; */

			transform: translateY(-135px);
		}
	}

	.nav__trigger__icon {
		@apply relative;

		width: 26px;
		height: 18px;
	}

	.nav__trigger__line {
		@apply absolute block;

		width: 26px;
		height: 1px;
		background-color: var(--white);
		transition: transform 0.3s, opacity 0.3s var(--transition_02);
		will-change: transform, opacity;

		&:nth-child(1) {
			top: 0;
		}
		&:nth-child(2) {
			top: 7px;
			opacity: 1;
		}
		&:nth-child(3) {
			top: 14px;
		}

		.nav__trigger__btn.is-active & {
			&:nth-of-type(1) {
				top: 7px;
				transform: rotate(45deg);
			}
			&:nth-of-type(2) {
				opacity: 0;
			}
			&:nth-of-type(3) {
				top: 7px;
				transform: rotate(-45deg);
			}
		}
	}
}

/*--------------------
  SP Nav Modal
--------------------*/
@screen <lg {
	.nav__modal {
		@apply fixed z-modalNav top-0 left-0 flex items-center justify-center min-h-screen w-full overflow-hidden;

		/* height: calc(var(--vh, 1vh) * 100); */
		/* will-change: transform; */
	}

	.nav__modal__wrap {
		@apply flex z-content flex-col relative items-center justify-center w-full;
	}

	.nav__modal__toggle {
		@apply absolute top-0 right-0 flex items-center justify-center;

		width: 65px;
		height: 100%;
		z-index: 2;

		/* Arrow icon */
		&::after {
			border: solid var(--cardinal-red);
			content: '';
			transition: transform 0.3s var(--transition_03);

			@apply block absolute	icon-arrow icon-arrow-down;

			bottom: 15px;
			right: auto;
		}

		&.is-active {
			&::after {
				@apply icon-arrow-up;
			}
		}
	}

	.nav__modal__main {
		@apply box-border my-0 mx-auto pt-32 pb-24 px-5;
	}

	.nav__modal__bg {
		@apply fixed z-bg h-screen w-full top-0 left-0;

		background-color: var(--white);
	}

	.nav__modal__menu {
		@apply mb-4;

		position: relative;
		z-index: 1;
	}

	.nav__modal__link {
		/* Level 01 */
		&.lev1 {
			@apply uppercase nav-modal-txt;

			padding: 0 80px 0 10px;
		}

		/* Level 02 */
		&.lev2 {
			@apply text-s1;

			/* Counter Num*/
			&::before {
				@apply mr-2;

				counter-increment: itemcounter;
				content: counters(itemcounter, '.', decimal-leading-zero);
				/* color: var(--cardinal-red); */
				font-size: clamp(8px, 1rem, 10px);
			}
		}

		/* Level 03 */
		&.lev3 {
			@apply text-s1;

			padding: 4px 0 4px 10px;
			background: resolve('icon_arrow_r_b.svg') left top 10px/5px 8px no-repeat;
		}

		.nav__modal__item > & {
			@apply relative flex items-center ml-7 py-1 px-2;
		}
	}

	.nav__modal__menu__lev1 {
		position: relative;
		z-index: 1;

		.nav__modal__link {
			.en {
				@apply block title3-en;

				color: var(--cardinal-red);
				font-family: var(--font-sans-en), sans-serif;
				font-weight: 400;
			}

			.ja {
				@apply block mt-2 title2-ja;

				font-family: var(--font-serif-ja), serif;
				font-weight: 400;
				line-height: 1.2;
			}
		}
	}

	.nav__modal__menu__lev2 {
		position: relative;
		user-select: none;
		counter-reset: itemcounter;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s var(--transition_01);

		&.is-show {
			.nav__modal__item {
				opacity: 1;
			}
		}
	}

	.nav__modal__item {
		position: relative;
		opacity: 0;
		transition: opacity 0.3s var(--transition_01);
	}

	.nav__modal__menu__lev3 {
		padding: 10px 65px 0px 30px;
		background-color: var(--base-light-color);
	}

	.links__link {
		.modal__links & {
			@apply flex items-center justify-center mx-3;

			width: 48px;
			height: 48px;

			&::before {
				@apply absolute block rounded-full;

				width: 40px;
				height: 40px;
				background-color: var(--cardinal-red);
				content: '';
			}
		}
	}

	.modal__links {
		@apply fixed z-content bottom-0 left-0 w-full mx-auto py-3 px-5;

		background-color: var(--light-gray);
	}

	.modal__links__menu {
		@apply flex justify-center;
	}
}

@screen >=lg {
	.nav__trigger,
	.nav__modal {
		@apply hidden;
	}
}
