/*--------------------
  Color
--------------------*/
:root {
	/* WF Light theme */
	--theme-color: #fff;
	--font-color: #5c5c5c;
	--font-gray-color: #b0b0b4;
	--font-light-color: #fff;
	--font-link-color: #42424c;
	--font-red-color: #cc0344;
	--base-light-color: #f4f4f4;
	--base-lighter-color: #dddbdb;
	--base-lightest-color: #f8f8f6;
	--base-white-color: #ffffff;
	--base-dark-color: #404040;
	--base-darker-color: #2e2e2e;
	--base-darkest-color: #101010;

	/* WF Dark theme  */

	/* Deisgn theme */
	--white: #fff;
	--black: #191818;
	--gray: #302f2f;
	--light-gray: #f2f2f2;
	--cardinal-red: #800a07;
	--cardinal-red-light: #bc0f0a;
	--royal-blue: #12254b;
	--midnight-blue: #00306c;
	--space-cadet: #1f335a;
	--space-cadet-light: #243b68;
	--white-gold: #ddd9d0;
	--gold: #a49a82;
	--isabelline: #efeeec;

	/* Gradient */
	/* --blue-grad: linear-gradient(135deg, #0d1a35 2%, #12254b 100%); */
	--blue-grad: linear-gradient(90deg, #0d1a35 2%, #12254b 100%);
	--blue-light-grad: linear-gradient(135deg, #00306c 0%, #002757 100%);
	--blue-grad-overlay: linear-gradient(
		135deg,
		rgba(19, 36, 74, 0) 0%,
		rgba(19, 36, 74, 0.4) 100%
	);
	--red-grad: linear-gradient(135deg, #821619 0%, #600f0a 100%);
	--gold-grad: linear-gradient(135deg, #e8e7e4 0%, #c9c1b2 100%);
	--black-grad: linear-gradient(to bottom, #504e4b 0%, #191818 100%);

	/* Animation Easing */
	--transition_01: cubic-bezier(0.075, 0.82, 0.165, 1);
	--transition_02: cubic-bezier(0.49, 0.01, 0.19, 0.89);
	--transition_03: cubic-bezier(0.32, 0.181, 0.3, 1);
	--expo-ease: cubic-bezier(0, 0.62, 0, 0.99);
	--smooth-ease: cubic-bezier(0.2, 1, 0.24, 1);
	--slow-fade: cubic-bezier(0.41, 0.08, 0.73, 0.85);
	--ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
	--expo-in-out: cubic-bezier(0.89, 0.01, 0, 1);
	--sleek-out: cubic-bezier(0.8, 0, 0.172, 1);
}

/*--------------------
  Icon
--------------------*/

.icon-toggle {
	width: 10px;
	height: 10px;
	position: relative;
	will-change: transform;

	&::before,
	&::after {
		position: absolute;
		z-index: 2;
		content: '';
		transform: translate(50%, 50%);
		background-color: var(--cardinal-red);
	}

	&::after {
		transition: transform 0.5s var(--transition_01);
		width: 1px;
		height: 10px;
		bottom: 50%;
		left: calc(50% - 1px);
	}
	&::before {
		width: 10px;
		height: 1px;
		bottom: 50%;
		left: -50%;
	}
}

.icon-toggle-active {
	&::after {
		transform: translateY(50%) rotate(0) rotate(90deg);
		transition: transform 0.5s $transition_01;
	}
	&::before {
		opacity: 0;
		transform: translateY(50%) rotate(0) rotate(90deg);
		transition: transform 0.5s $transition_01, opacity 0.5s $transition_01;
	}
}

/* Base */
.icon-bg {
	@apply block absolute top-1/2;

	content: '';
	background-repeat: no-repeat;
	background-position: right 0 center;
	transform: translateY(-50%);
}

/* Link button */
.icon-link-in-w {
	width: 8px;
	height: 16px;
	background-image: resolve('icon_arrow_r.svg');
	background-size: 8px 16px;
}

.icon-link-in-b {
	right: 20px;
	width: 7px;
	height: 14px;
	background-image: resolve('icon_arrow_r_b.svg');
	background-size: 7px 14px;
}

.icon-link-ex-w {
	right: 20px;
	width: 20px;
	height: 15px;
	background-image: resolve('icon_link_ext_w.svg');
	background-size: 20px 15px;
}

.icon-link-ex-b {
	right: 20px;
	width: 20px;
	height: 15px;
	background-image: resolve('icon_link_ext_b.svg');
	background-size: 20px 15px;
}

.icon-link-anchor {
	right: 20px;
	width: 11px;
	height: 14px;
	background-image: resolve('icon_link_anchor_w.svg');
	background-size: 11px 14px;
}

.icon-arrow-r {
	left: 0;
	width: 4px;
	height: 8px;
	background-image: resolve('icon_arrow_r_b.svg');
	background-size: 4px 8px;
}

/* Header links */
.icon-access {
	width: 14px;
	height: 18px;
	background-image: resolve('icon_access.svg');
	background-size: 14px 18px;
}

@screen >=lg {
	.icon-access {
		left: 12px;
	}
}

.icon-english {
	width: 16px;
	height: 16px;
	background-image: resolve('icon_english.svg');
	background-size: 16px 16px;
}

@screen >=lg {
	.icon-english {
		left: 12px;
	}
}

.icon-contact {
	width: 18px;
	height: 12px;
	background-image: resolve('icon_contact.svg');
	background-size: 18px 12px;
}

@screen >=lg {
	.icon-contact {
		left: 10px;
	}
}

.icon-magazine {
	width: 17px;
	height: 20px;
	background-image: resolve('icon_magazine.svg');
	background-size: 17px 20px;
}

@screen >=lg {
	.icon-magazine {
		left: 10px;
	}
}

.icon-info {
	width: 19px;
	height: 14px;
	background-image: resolve('icon_more-list.svg');
	background-size: 19px 14px;
}

@screen <md {
	.icon-info {
		left: 14px;
	}
}

@screen >=md {
	.icon-info {
		left: 20px;
	}
}

.icon-arrow {
	border-width: 0 1px 1px 0;
}

@screen <lg {
	.icon-arrow {
		right: 7px;
		width: 7px;
		height: 7px;
	}
}

@screen >=lg {
	.icon-arrow {
		right: 5px;
		width: 5px;
		height: 5px;
	}
}

.icon-arrow-right {
	transform: rotate(-45deg);
}

.icon-arrow-left {
	transform: rotate(135deg);
}

.icon-arrow-up {
	transform: rotate(-135deg);
}

.icon-arrow-down {
	transform: translateY(-55%) rotate(45deg);
}

.icon-search {
	width: 17px;
	height: 17px;
	background-image: resolve('icon_search.svg');
	background-size: 17px 17px;
}

@screen >=lg {
	.icon-search {
		left: 12px;
	}
}

.icon-close {
	@apply absolute z-content top-0 flex flex-col items-center justify-center;
}

@screen <md {
	.icon-close {
		right: 0;
		width: 80px;
		height: 80px;
	}
}

@screen >=md {
	.icon-close {
		right: -120px;
		width: 120px;
		height: 120px;
	}
}

.icon-close__lines {
	@apply relative block;

	width: 26px;
	height: 20px;
}

.icon-close__line {
	@apply absolute block;

	width: 26px;
	height: 1px;
	background-color: var(--royal-blue);

	&:nth-of-type(1) {
		top: 7px;
		transform: rotate(45deg);
	}
	&:nth-of-type(2) {
		top: 7px;
		transform: rotate(-45deg);
	}
}

.icon-close__text {
	@apply mt-2 uppercase text-s3;

	font-family: var(--font-sans-en), sans;
}

.sns__item__link {
	@apply flex items-center justify-center;
}

.sns__icon {
	transition: opacity 0.3s var(--transition_03);

	&:hover {
		@apply opacity-80;
	}

	&.fb {
		width: 22px;
		height: 22px;
	}

	&.tw {
		width: 20px;
		height: 21px;
	}

	&.yt {
		width: 29px;
		height: 20px;
	}
}
