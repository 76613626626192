.is-close {
	pointer-events: none;
}

.is-open {
	pointer-events: auto;
}

.flip-view {
	@apply cursor-pointer;
	/*
	.flip-image {
		@apply scale-100;
		transition: transform 0.4s var(--transition_03);
	}

	&:hover {
		.flip-image {
			@apply scale-105;
		}
	} */
}

.flip-view-bg {
	@apply absolute z-bg top-0 left-0 w-full h-full;

	background-color: var(--white);
}

.flip-cont {
	@apply absolute inset-0 h-full invisible;
}

.flip-cont-bg {
	@apply absolute top-0 left-0 w-full h-full;
}

.flip-cont-item {
	@apply absolute top-0 left-0 invisible select-none;

	/* transition: opacity 0.4s var(--transition_03),
		visibility 0.4s var(--transition_03); */
	z-index: 1;

	&.is-open {
		@apply visible select-auto;
	}

	&.is-prev {
		@apply invisible select-none;

		z-index: 2;
	}

	&.is-current {
		@apply visible select-auto;

		z-index: 3;
	}
}

.flip-image {
	@apply w-full h-full object-cover object-center;

	user-select: none;
}

.cont__nav {
	@apply absolute right-0 flex flex-col items-center justify-center;

	background-color: var(--light-gray);
}

.cont__nav__menu {
	@apply w-full;
}

.cont__nav__item {
	@apply text-center text-s1 cursor-pointer;

	line-height: 60px;
	font-family: var(--font-num), serif;
}

@screen <md {
	.cont__nav {
		@apply z-content w-full bottom-0;

		height: 60px;
	}

	.cont__nav__menu {
		@apply flex justify-center;
	}

	.cont__nav__item {
		min-width: 48px;
		max-width: 60px;
	}
}

@screen >=md {
	.cont__nav {
		@apply z-bg top-1/2 h-full;

		width: 120px;
		transform: translateY(-50%);
	}

	.cont__nav__menu {
	}

	.cont__nav__item {
		@apply w-full;
	}
}

.btn-view {
	@apply absolute right-4 bottom-4 flex items-center justify-center;

	width: 48px;
	height: 48px;
	background-color: var(--cardinal-red);
	border-radius: 50%;
}
