/* Gradient text */
.text-grad {
	/* background-color: var(--font-color); */
	background-image: linear-gradient(135deg, #b04ac2 0%, #1f49d3 100%);
	color: transparent;

	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}

.text-line {
	/* color: var(--theme-color); */
	/* text-shadow: 1px 1px 0 #fff, -1px -1px 0 #fff, -1px 1px 0 #fff,
		1px -1px 0 #fff, 0px 1px 0 #fff, 0-1px 0 #fff, -1px 0 0 #fff, 1px 0 0 #fff; */

	color: transparent;
	-webkit-text-stroke: 2px white;
	text-stroke: 2px white;
	/* -webkit-text-fill-color: white; */
}

.text-line-grad {
	background-image: linear-gradient(135deg, #b04ac2 0%, #1f49d3 100%);
	/* -webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent; */
	-webkit-background-clip: text;
	-webkit-text-stroke: 6px transparent;
	color: var(--theme-color);
}

/* Gradient line */
.line-grad {
	border: 6px solid #b04ac2;
	border-image: linear-gradient(135deg, #b04ac2 0%, #1f49d3 100%) 1;
}

.line-grad-top {
	border-top: 6px solid #b04ac2;
	border-image: linear-gradient(135deg, #b04ac2 0%, #1f49d3 100%) 1;
	border-right: 0;
	border-bottom: 0;
	border-left: 0;
}
