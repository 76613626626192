@screen <md {
	.nav__sub {
		display: none;
	}
}

@screen >=md {
	.nav__sub {
		@apply absolute z-pulldown top-0 left-0 w-full h-full invisible;

		transition: visibility 0.3s var(--sleek-out) 0.6s;

		&.is-active {
			@apply visible;

			transition: visibility 0.3s var(--sleek-out);
		}
	}

	.nav__sub__bg {
		@apply absolute z-bg inset-0 w-full h-full opacity-0 invisible;

		background-color: var(--base-darkest-color);
		transition: opacity 0.3s ease, visibility 0.3s ease;

		.nav__sub.is-active & {
			@apply opacity-40 visible;

			transition: opacity 0.3s ease, visibility 0.3s ease;
		}
	}

	.nav__sub__content__bg {
		@apply absolute z-bg left-0 w-full;

		top: 128px;
		min-height: 0;
		background-color: var(--base-lightest-color);
		transition: min-height 0.3s var(--sleek-out) 0.3s;

		.nav__sub__content.is-current & {
			transition: min-height 0.3s var(--expo-ease);
		}
	}

	.nav__sub__content {
		@apply absolute z-content left-0 w-full;

		top: 128px;
		visibility: hidden;
		transition: visibility 0.6s var(--sleek-out) 0.3s;

		&.is-current {
			/* z-index: 1; */
			visibility: visible;
		}
	}

	.nav__sub__content__wrapper {
		display: flex;
		position: relative;
		opacity: 0;
		transition: opacity 0.3s var(--sleek-out);

		.nav__sub__content.is-current & {
			opacity: 1;
			transition: opacity 0.3s var(--sleek-out) 0.38s;
		}
	}

	/* Hover animation */
	.nav__sub__slider {
		position: relative;
		width: 33.33%;
		min-height: 18rem;
	}

	.nav__sub__slider__medias {
		@apply absolute top-0 left-0 w-full h-full;
	}

	.nav__sub__slider__media {
		@apply absolute top-0 left-0 w-full h-full;

		overflow: hidden;
		z-index: 0;

		&.is-prev {
			z-index: 1;
		}

		&.is-current {
			animation: hover-mask-wrapper 0.48s var(--sleek-out);
			z-index: 2;
		}
	}

	.nav__sub__slider__media__image {
		@apply absolute top-0 left-0 w-full h-full;

		object-fit: cover;
		object-position: center center;
		image-rendering: -webkit-optimize-contrast;

		.nav__sub__slider__media.is-current & {
			animation: hover-mask 0.48s var(--sleek-out);
		}
	}

	.nav__sub__media {
		width: 100%;
		overflow: hidden;
	}
	.nav__sub__media__image {
		@apply fixed top-0 left-0 w-full h-full;

		height: 100%;
		filter: grayscale(1);
	}

	.nav__sub__menu {
		@apply flex;

		width: 66.66%;
		margin: 2rem;
	}

	.nav__sub__label {
		@apply py-1;
	}

	.nav__sub__label__text {
		.en {
			@apply title3-en uppercase;

			color: var(--cardinal-red);
		}

		.ja {
			@apply block title2-ja;

			font-family: var(--font-serif-ja), serif;
			font-weight: 400;
		}
	}

	.nav__sub__menu__text {
		@apply ml-16;
	}

	.nav__sub__list {
		counter-reset: itemcounter;
	}

	.nav__sub__item {
		line-height: 1.5;
	}

	.nav__sub__link {
		@apply py-2;

		.en {
			@apply title3-en uppercase;

			color: var(--cardinal-red);
		}

		.ja {
			@apply flex items-center pulldown-txt-ja;

			font-family: var(--font-sans-en), var(--font-sans-ja), sans-serif;
			font-weight: 400;

			&::before {
				@apply pr-2;

				color: var(--cardinal-red);
				counter-increment: itemcounter;
				content: counters(itemcounter, '.', decimal-leading-zero);
				font-size: clamp(9px, 1.1rem, 11px);
			}
		}
	}
}
