/*--------------------
  Header links
--------------------*/
.links-txt {
	letter-spacing: 0.1rem;
	font-size: clamp(10px, 1.2vw, 12px);
}

/*--------------------
  Navigation
--------------------*/
.nav-txt {
	letter-spacing: 0.1rem;
	line-height: 1;
}

@screen <md {
	.nav-txt {
		font-size: clamp(12px, 1.4vw, 14px);
	}
}

@screen >=md {
	.nav-txt {
		font-size: clamp(14px, 1.5vw, 15px);
	}
}

.nav-modal-txt {
	letter-spacing: 0.2rem;
}

@screen <md {
	.nav-modal-txt {
		font-size: clamp(24px, 4.4vw, 30px);
	}
}

@screen >=md {
	.nav-modal-txt {
		font-size: clamp(54px, 6.4vw, 64px);
	}
}

.pulldown-txt-ja {
	letter-spacing: 0.05em;
}

@screen <md {
	.pulldown-txt-ja {
		font-size: clamp(12px, 1.4vw, 14px);
	}
}

@screen >=md {
	.pulldown-txt-ja {
		font-size: clamp(14px, 1.5vw, 15px);
	}
}

/*--------------------
  Footer
--------------------*/

@screen <md {
	.footer-col-en {
		font-size: clamp(60px, 6vw, 60px);
		letter-spacing: 0.2rem;
	}
}

@screen >=md {
	.footer-col-en {
		font-size: clamp(80px, 10vw, 100px);
		letter-spacing: 0.7rem;
	}
}

.footer-col-ja {
	letter-spacing: 0.2rem;
}

/* @screen >=md { */
.footer-txt-en {
	letter-spacing: 0.1rem;
	line-height: 1;
	font-size: 10px;
}

.footer-txt-ja {
	letter-spacing: 0.1rem;
	line-height: 1;
	font-size: clamp(14px, 1.6vw, 16px);
}
/* } */

.footer-txt2-ja {
	letter-spacing: 0.1rem;
}

@screen <md {
	.footer-txt2-ja {
		font-size: clamp(11px, 1.2vw, 12px);
		line-height: 1.4;
	}
}

@screen >=md {
	.footer-txt2-ja {
		font-size: clamp(11px, 1.2vw, 12px);
		line-height: 1;
	}
}

/*--------------------
  Home
--------------------*/
/* Hero */
.hero-title-en {
	letter-spacing: 0.2rem;
}

@screen <md {
	.hero-title-en {
		font-size: clamp(54px, 6vw, 60px);
	}
}

@screen >=md {
	.hero-title-en {
		font-size: clamp(100px, 9vw, 120px);
	}
}

@screen sp-land {
	.hero-title-en {
		font-size: clamp(54px, 6vw, 60px);
	}
}

.hero-title-ja {
	letter-spacing: 0.2rem;
	font-size: clamp(20px, 2.4vw, 24px);
}

@screen sp-land {
	.hero-title-ja {
		font-size: clamp(18px, 14vw, 20px);
	}
}

/* Action */

.action-title-en {
	letter-spacing: 0.1rem;
}

@screen <md {
	.action-title-en {
		font-size: clamp(50px, 6vw, 56px);
	}
}

@screen >=md {
	.action-title-en {
		font-size: clamp(80px, 8.5vw, 85px);
	}
}

@screen sp-land {
	.action-title-en {
		font-size: clamp(60px, 7vw, 70px);
	}
}

.action-title-ja {
	letter-spacing: 0.2rem;
}

@screen <md {
	.action-title-ja {
		font-size: clamp(16px, 2vw, 20px);
	}
}

@screen >=md {
	.action-title-ja {
		font-size: clamp(20px, 2.2vw, 22px);
	}
}

.action-menu-ja {
	letter-spacing: 0.2rem;
}

@screen <md {
	.action-menu-ja {
		font-size: clamp(16px, 2vw, 20px);
	}
}

@screen >=md {
	.action-menu-ja {
		font-size: clamp(20px, 2.2vw, 22px);
	}
}

.action-title-overlay {
	letter-spacing: 0.1rem;
}

@screen <md {
	.action-title-overlay {
		font-size: clamp(160px, 20vw, 200px);
	}
}

@screen >=md {
	.action-title-overlay {
		font-size: clamp(220px, 26vw, 260px);
	}
}

/* Section */
.title1-en {
}

@screen <md {
	.title1-en {
		font-size: clamp(50px, 6vw, 56px);
	}
}

@screen >=md {
	.title1-en {
		font-size: clamp(140px, 15vw, 150px);
		letter-spacing: 0.1rem;
	}
}

@screen sp-land {
	.title1-en {
		font-size: clamp(60px, 7vw, 70px);
	}
}

.title2-en {
	letter-spacing: 0.1rem;
}

@screen <md {
	.title2-en {
		font-size: clamp(42px, 6vw, 60px);
	}
}

@screen >=md {
	.title2-en {
		font-size: clamp(80px, 8.5vw, 85px);
	}
}

@screen sp-land {
	.title2-en {
		font-size: clamp(60px, 7vw, 70px);
	}
}

.title3-en {
	letter-spacing: 0.1rem;
	font-size: clamp(10px, 1.2vw, 12px);
}

.title1-ja {
	letter-spacing: 0.2rem;
}

@screen <md {
	.title1-ja {
		font-size: clamp(22px, 3vw, 30px);
	}
}

@screen >=md {
	.title1-ja {
		font-size: clamp(26px, 3vw, 30px);
	}
}

.title2-ja {
	letter-spacing: 0.15rem;
}

@screen <md {
	.title2-ja {
		font-size: clamp(16px, 2vw, 20px);
	}
}

@screen >=md {
	.title2-ja {
		font-size: clamp(20px, 2.2vw, 22px);
	}
}

.title3-ja {
	letter-spacing: 0.2rem;
}

@screen <md {
	.title3-ja {
		font-size: clamp(16px, 2vw, 18px);
	}
}

@screen >=md {
	.title3-ja {
		font-size: clamp(18px, 2vw, 20px);
	}
}

@screen <md {
	.title4-ja {
		font-size: clamp(14px, 1.6vw, 16px);
		letter-spacing: 0.1rem;
	}
}

@screen >=md {
	.title4-ja {
		font-size: clamp(16px, 1.8vw, 18px);
		letter-spacing: 0.2rem;
	}
}

@screen <md {
	.title5-ja {
		font-size: clamp(14px, 1.6vw, 16px);
		letter-spacing: 0.1rem;
	}
}

@screen >=md {
	.title5-ja {
		font-size: clamp(14px, 1.6vw, 16px);
		letter-spacing: 0.2rem;
	}
}

/* Links */
@screen <md {
	.section-link-txt {
		font-size: clamp(12px, 1.4vw, 14px);
		/* letter-spacing: 0.1rem; */
	}
}

@screen >=md {
	.section-link-txt {
		font-size: clamp(14px, 1.6vw, 16px);
		letter-spacing: 0.2rem;
	}
}

/*--------------------
  Form
--------------------*/
.text-f1 {
	letter-spacing: 0.1rem;
	line-height: 1.1;
}

@screen <md {
	.text-f1 {
		font-size: clamp(40px, 4vw, 54px);
	}
}

@screen >=md {
	.text-f1 {
		font-size: clamp(50px, 5vw, 70px);
	}
}

.text-f2 {
	letter-spacing: 0.1rem;
}

@screen <md {
	.text-f2 {
		font-size: clamp(8px, 1vw, 10px);
		line-height: 2.3;
	}
}

@screen >=md {
	.text-f2 {
		font-size: clamp(10px, 1vw, 12px);
		line-height: 2.2;
	}
}

.text-month {
	font-size: clamp(10px, 1vw, 12px);
	letter-spacing: 0.1rem;
}

.text-day {
	font-size: clamp(35px, 4vw, 40px);
	letter-spacing: 0.1rem;
}

/*--------------------
  KV
--------------------*/
.kv-en {
	letter-spacing: 0.2rem;
}

.kv-ja {
	letter-spacing: 0.3rem;
}

@screen <md {
	.kv-en {
		font-size: clamp(10px, 7vw, 12px);
		line-height: 0.8;
	}

	.kv-ja {
		font-size: clamp(18px, 3vw, 24px);
		line-height: 1.2;
	}
}

@screen >=md {
	.kv-en {
		font-size: clamp(12px, 1.8vw, 14px);
		line-height: 0.8;
	}

	.kv-ja {
		font-size: clamp(26px, 3vw, 30px);
		line-height: 1.2;
	}
}

/*--------------------
  Title - Large
--------------------*/
.text-ll {
	letter-spacing: 0.2rem;
}

@screen <md {
	.text-ll {
		font-size: clamp(20px, 2.4vw, 24px);
		line-height: 1.77;
	}
}

@screen >=md {
	.text-ll {
		font-size: clamp(26px, 3.2vw, 30px);
		line-height: 1.7;
	}
}

/*--------------------
  Title - Medium
--------------------*/
.text-lm {
	letter-spacing: 0.2rem;
}

@screen <md {
	.text-lm {
		font-size: clamp(18px, 2vw, 20px);
		line-height: 1.77;
	}
}

@screen >=md {
	.text-lm {
		font-size: clamp(20px, 3vw, 24px);
		line-height: 1.7;
	}
}

/*--------------------
  Title - Small
--------------------*/
.text-ls {
	letter-spacing: 0.2rem;
}

@screen <md {
	.text-ls {
		font-size: clamp(16px, 1.8vw, 18px);
		line-height: 1.77;
	}
}

@screen >=md {
	.text-ls {
		font-size: clamp(18px, 2vw, 20px);
		line-height: 1.7;
	}
}

/*--------------------
  Text - Medium
--------------------*/
.text-m1 {
	letter-spacing: 0.2rem;
}

@screen <md {
	.text-m1 {
		font-size: clamp(12px, 1.4vw, 14px);
		line-height: 1.8;
	}
}

@screen >=md {
	.text-m1 {
		font-size: clamp(14px, 1.6vw, 16px);
		line-height: 2;
	}
}

/*--------------------
  Text - Small
--------------------*/
.text-s1 {
	letter-spacing: 0.08rem;
}

@screen <md {
	.text-s1 {
		font-size: clamp(12px, 1.4vw, 14px);
		line-height: 1.7;
	}
}

@screen >=md {
	.text-s1 {
		font-size: clamp(14px, 1.6vw, 16px);
		line-height: 1.77;
	}
}

.text-s2 {
	letter-spacing: 0.1rem;
}

@screen <md {
	.text-s2 {
		font-size: 12px;
		/* font-size: clamp(16px, 2vw, 18px); */
	}
}

@screen >=md {
	.text-s2 {
		font-size: 14px;
		/* font-size: clamp(16px, 2.2vw, 18px); */
	}
}

@screen <md {
	.text-s3 {
		font-size: 10px;
		/* font-size: clamp(16px, 2vw, 18px); */
	}
}

@screen >=md {
	.text-s3 {
		font-size: 12px;
		/* font-size: clamp(16px, 2.2vw, 18px); */
	}
}

.text-s4 {
	font-size: 10px;
	/* font-size: clamp(16px, 2vw, 18px); */
}
