.scroll__indicator {
	@apply absolute right-0 flex items-center z-bar cursor-pointer;

	width: 48px;
	bottom: 6.5rem;
	writing-mode: vertical-rl;
	transition: visibility 0.3s var(--transition_03),
		opacity 0.3s var(--transition_03);

	&.is-hide {
		@apply opacity-0 invisible;
	}
}

@include media('<phone') {
	.scroll__indicator {
		@apply right-4;
	}
}

@include media('>=phone') {
	.scroll__indicator {
		@apply right-12;
	}
}

.scroll__indicator__text {
	/* @include scroll-indicator-text; */
	@apply font-bold;

	.scroll__indicator & {
		color: var(--font-color);
	}
}

.scroll__indicator__icon {
	@apply opacity-0 fill-white;

	width: 5px;
	height: 10px;
	margin-top: 5px;
	animation: scroll-drop 1.6s var(--transition_02) infinite 3s;

	.scroll__indicator & {
		color: var(--font-color);
	}
}
