.advantage {
	@apply relative z-overlap overflow-hidden;
}

@screen <md {
	.advantage {
		@apply pt-20;
	}
}

@screen >=md {
	.advantage {
		@apply pt-32;
	}
}

.advantage__main__wrap {
	@apply relative;
}

@screen <md {
	.advantage__main__wrap {
	}
}

@screen >=md {
	.advantage__main__wrap {
		@apply flex flex-col justify-center;

		min-height: 50vh;
	}
}

.advantage__title {
	@apply relative z-content;

	.en,
	.ja {
		@apply block overflow-hidden;

		span {
			@apply block;
		}
	}

	/* .ja {
		@apply hidden;
	} */
}

@screen <md {
	.advantage__title {
		line-height: 1.77;
	}
}

@screen >=md {
	.advantage__title {
		line-height: 1.7;
	}
}

.advantage__text {
	.en {
		@apply block overflow-hidden;

		span {
			@apply inline-block;
		}
	}

	.ja {
		span {
			@apply block overflow-hidden;

			span {
				@apply inline-block;
			}
		}
	}
}

@screen <md {
	.advantage__text {
		@apply mt-10;
	}
}

@screen >=md {
	.advantage__text {
		@apply relative z-content mt-10;
	}
}

@screen sp-land {
	.advantage__text {
		@apply ml-0;
	}
}

.advantage__view {
	@apply flip-view;
}

.advantage__view-bg {
	@apply flip-view-bg;
}

@screen <md {
	.advantage__media {
		@apply right-0 h-60 mt-16;
	}
}

@screen >=md {
	.advantage__media {
		@apply absolute z-bg top-0.5 right-14 w-1/3 h-full;
	}
}

/* .advantage-flip-media {
	img {
		height: 13rem;
	}
} */

.advantage__image {
	@apply flip-image;
}

.advantage__sub {
	@apply overflow-hidden;

	background-color: var(--isabelline);
}

@screen <md {
	.advantage__sub {
		@apply mt-20;
	}
}

@screen >=md {
	.advantage__sub {
		@apply mt-32;
	}
}

.advantage__sub__wrap {
}

@screen <md {
	.advantage__sub__wrap {
		@apply py-20;
	}
}

@screen >=md {
	.advantage__sub__wrap {
		@apply py-32;
	}
}

.advantage__item__title {
	@apply relative;

	.en {
		@apply uppercase;

		color: var(--cardinal-red);
	}
}

@screen <md {
	.advantage__item__title {
		@apply px-6 py-6;

		.ja {
			@apply mt-2;
		}
	}
}

@screen >=md {
	.advantage__item__title {
		@apply px-10 py-10;

		.ja {
			@apply mt-4;
		}
	}
}

.advantage__item__media {
	@apply relative;
}

.advantage__cont {
	@apply flip-cont;
}

.advantage__cont__wrap {
	@apply relative h-full;
}

.advantage__cont__bg {
	@apply flip-cont-bg;

	background-color: var(--white);
}

.advantage__cont__item {
	@apply flex flex-col h-full flip-cont-item;
}

@screen <md {
	.advantage__cont__item {
		@apply justify-center w-full pt-20 pb-28 px-10;
	}
}

@screen >=md {
	.advantage__cont__item {
		@apply justify-end pt-32 pl-32;

		width: calc(100% - 120px);
	}
}

.advantage__cont__title {
	@apply invisible;
	/*
	.en {
		span {
			@apply inline-block overflow-hidden;

			span {
				@apply block;
			}
		}
	}

	.ja {
		@apply overflow-hidden;

		span {
			@apply block;

		}
	} */
}

@screen <md {
	.advantage__cont__title {
		.ja {
			@apply mt-2;
		}
	}
}

.advantage__cont__col2 {
	@apply grid gap-8;
}

@screen <md {
	.advantage__cont__col2 {
		@apply grid-cols-1 mt-10;
	}
}

@screen >=md {
	.advantage__cont__col2 {
		@apply grid-cols-2 mt-20;
	}
}

.advantage__cont__text {
	.sec__text {
		@apply block overflow-hidden;
	}
}

@screen <md {
	.advantage__cont__media {
		/* @apply col-span-2; */

		height: 25vh;
	}
}

@screen >=md {
	.advantage__cont__media {
		/* @apply col-span-2; */

		height: 50vh;

		/* img {
			height: 100%;
		} */
	}
}

@screen <md {
	.advantage__cont__labels {
		@apply hidden;
	}
}

@screen >=md {
	.advantage__cont__labels {
		@apply absolute top-0 left-0 flex items-center justify-between w-full h-32 pl-32;

		padding-right: 120px;
	}

	.advantage__label__title {
		@apply uppercase text-m1;

		font-family: var(--font-serif-en), serif;
	}

	.advantage__label__num {
		@apply mr-10 text-s1;

		font-family: var(--font-num), serif;
	}
}

.advantage__cont__nav {
	@apply cont__nav;
}

.advantage__cont__nav__menu {
	@apply cont__nav__menu;
}

.advantage__cont__nav__item {
	@apply cont__nav__item;

	&.is-current {
		background-color: var(--base-dark-color);
	}
}

.advantage__flip-close {
	@apply icon-close;

	background-color: var(--cardinal-red);

	.icon-close__line {
		background-color: var(--white);
	}

	.icon-close__text {
		color: var(--white);
	}
}

.advantage__view-btn {
	@apply relative z-overlap btn-view;
}
