.home__slider {
	@apply relative flex items-center justify-center w-full h-screen overflow-hidden;
}
/*
.slider__title {
	@apply absolute w-1/2 mt-6 mx-auto z-content h-1/4;
}

.slider__title__text {
	@apply text-center uppercase;

	color: var(--font-color);
}

.slider__title__text--main,
.slider__title__text--sub {
	@apply block font-normal overflow-hidden;
}
@screen lg {
	.slider__title__text--main,
	.slider__title__text--sub {
		.break {
			@apply hidden;
		}
	}
}

.slider__title__text--main {
	@apply text-6xl leading-10 tracking-wider;
}

.slider__title__text--sub {
	@apply mt-2 text-xl;
} */

.slider {
	@apply relative h-full overflow-hidden z-bg;
}

.slider__medias {
	@apply absolute h-full w-full inset-0 overflow-hidden;
}

.slider__description {
	@apply absolute bottom-0 left-0 w-1/4 h-1/4 box-border flex flex-col items-center justify-center bg-white z-content;
}

.slide__media,
.slide__title {
	@apply absolute h-full w-full inset-0 overflow-hidden;

	.slider:not(:first-child) {
		@apply invisible opacity-0;
	}

	&:nth-child(n + 2) {
		@apply invisible;
	}
}

.slide__media {
	background-color: var(--black);
}

.slide__image {
	@apply absolute h-full w-full inset-0 object-cover object-center;

	image-rendering: -webkit-optimize-contrast;
}

@screen <md {
	.slide__image {
		mask-image: linear-gradient(
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 1) 50%,
			rgba(0, 0, 0, 0) 80%,
			rgba(0, 0, 0, 0) 100%
		);
	}
}

@screen >=md {
	.slide__image {
		mask-image: linear-gradient(
			/* to right, */ 110deg,
			rgba(0, 0, 0, 1) 0%,
			rgba(0, 0, 0, 1) 50%,
			rgba(0, 0, 0, 0) 80%,
			rgba(0, 0, 0, 0) 100%
		);
	}
}
/*
.slide__title {
	@apply relative box-border flex flex-col justify-center text-2xl text-black;

	&:nth-child(n + 2) {
		@apply absolute top-0 left-0 invisible;
	}
}

@screen <md {
	.slide__title {
		@apply py-2 px-4;
	}
}

@screen >=md {
	.slide__title {
		@apply py-4 px-6;
	}
}

.slide__title__item {
	@apply font-light;
} */

.slider__controls {
	@apply float-right absolute h-full w-full top-0 left-0;

	display: none;
}

.slider__controls__next,
.slider__controls__prev {
	@apply w-1/2 h-full;
	/* background-color: $white; */
	/* opacity: 0.3; */
	display: none;
}
