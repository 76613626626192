.experience {
	background-color: var(--isabelline);
}

@screen <md {
	.experience {
		@apply pb-20;
	}
}

@screen >=md {
	.experience {
		@apply pb-32;
	}
}

.experience__item {
	@apply relative py-4 overflow-hidden hover-wipe-active;

	background-color: var(--isabelline);
	transition: background-color 0.48s var(--transition_03);

	&:before {
		background-color: var(--royal-blue);
	}

	&:hover {
		&:before {
			background-color: var(--royal-blue);
		}
	}

	&.is-active {
		&:before {
			background-color: var(--royal-blue);
		}
	}
}

.experience__item__wrap {
	@apply w-full;
}

@screen <md {
	.experience__item__wrap {
		@apply px-6;
	}
}

@screen >=md {
	.experience__item__wrap {
		@apply mx-auto px-14;

		max-width: 1440px;
	}
}

@screen <md {
	.experience__title {
		@apply pb-5;
	}
}

@screen >=md {
	.experience__title {
		@apply pb-8;
	}
}

.experience__item__title {
	@apply relative z-content;

	color: var(--royal-blue);
	transition: color 0.3s var(--transition_03);

	.experience__item:hover &,
	.experience__item.is-active & {
		color: var(--white);
	}

	.sec__title2 {
		transition: transform 0.3s var(--transition_03);

		.experience__item:not(.is-active):hover & {
			transform: translateX(10px);
		}
	}
}

.experience__content {
	.col-left,
	.col-right {
		@apply py-10;
	}

	.col-left {
		@apply pl-10;
	}

	.col-right {
		@apply pr-10;
	}

	.sec__text,
	.sec__note {
		@apply relative z-content;

		color: var(--white);
	}

	.tbl {
		@apply relative z-content;
	}
}

@screen <md {
	.experience__content {
		.col-left {
			@apply px-10 pb-0;
		}

		.col-right {
			@apply px-10;

			&.media {
				height: 30vh;
			}
		}
	}
}

@screen >=md {
	.experience__content {
		.col-left {
			@apply pl-10;
		}

		.col-right {
			@apply pr-10;
		}
	}
}

.experience__link {
	@apply relative flex items-center justify-center mt-6 hover-wipe;

	width: 260px;
	height: 60px;
	border: 1px solid var(--white);
	color: var(--white);

	&::after {
		background-color: var(--white);
	}
}

@screen <md {
	.experience__link {
		max-width: 260px;
		width: 100%;
	}
}

@screen >=md {
	.experience__link {
		width: 260px;
	}
}

.experience__media {
	@apply opacity-0;

	&.pamphlet {
		transition: opacity 0.2s var(--transition_03);

		.experience__item.is-active & {
			@apply opacity-100;
		}
	}
}

@screen <md {
	.experience__media {
		&.pamphlet {
			@apply absolute bottom-0 left-1/2 w-2/3;

			height: 25vh;
			transform: translateX(-50%);
		}
	}
}

@screen >=md {
	.experience__media {
		&.pamphlet {
			@apply absolute top-0 right-0 w-1/2;

			height: 50vh;
		}
	}
}

.experience__image {
	@apply mx-auto;

	transition: transform 0.3s var(--transition_03);

	.experience__item.is-active & {
		transform: rotate(10deg);
	}
}

@screen <md {
	.experience__image {
		width: 100%;
	}
}

@screen >=md {
	.experience__image {
		width: 70%;
	}
}

.sec__link__text__wrap {
	@apply hover-rotate-wrap;

	height: 20px;
}

.sec__link__text {
	.experience__link:hover & {
		@apply block;

		color: var(--royal-blue);
		animation: hover-rotate 0.2s var(--transition_03);
	}
}

@screen <md {
	.mail-magazine {
		min-height: 300px;
	}
}

#mktoForm_1036 {
	width: 100% !important;
}

.mktoForm .mktoFormCol {
	float: none !important;
}

.mktoFieldWrap {
	@apply relative;

	width: 100% !important;
	color: var(--white);

	/* &:nth-child(n + 2) {
		@apply mt-4;
	} */
}

.mktoForm .mktoLabel {
	/* @apply absolute top-1/2 left-0 box-border block h-full px-4; */

	float: none !important;
	/* width: 10px !important; */
	/* padding-left: 1rem !important;*/
	/* padding-right: 1rem !important; */
	line-height: 30px !important;
	/* transform: translateY(-55%) !important; */
}

.mktoGutter,
.mktoOffset {
	display: none !important;
}

.mktoField {
	box-sizing: border-box !important;
	display: block !important;
	padding-left: 30px !important;
	padding-right: 1rem !important;
	width: 100% !important;
	line-height: 48px !important;
	color: var(--white) !important;
	background-color: var(--space-cadet) !important;
	outline: none !important;
	transition: background-color 0.3s var(--transition_03) !important;

	&:focus {
		background-color: var(--space-cadet-light) !important;
	}
}

.mktoForm .mktoAsterix {
	float: none !important;
	display: inline-block !important;
	color: var(--cardinal-red-light);
}

.mktoButtonRow {
	margin-top: 1rem !important;
}

.mktoButtonWrap {
	margin-left: 0 !important;
}

.mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton[type='submit'] {
	@apply block text-s1 mt-6 ml-auto px-10 cursor-pointer;

	width: 160px !important;
	margin-top: 0 !important;
	background-image: none !important;
	background-color: var(--cardinal-red) !important;
	border: none !important;
	color: var(--white) !important;
	/* font-weight: 500; */
	height: 48px !important;
	line-height: 48px !important;
	transition: opacity 0.3s var(--transition_03) !important;
	text-shadow: none !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;

	&:hover {
		opacity: 0.8 !important;
	}
}

/*
.mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton {
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 36px;
	line-height: 35px;
	padding: 0 20px;
	font-size: 13px;
	color: white;
	text-align: center;
	text-decoration: none;
	text-shadow: 0 -1px rgba(0, 0, 0, 0.4);
	background-clip: padding-box;
	border: 1px solid;
	border-radius: 2px;
	cursor: pointer;
	-webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.1),
		inset 0 0 0 1px rgba(255, 255, 255, 0.08), 0 1px 2px rgba(0, 0, 0, 0.25);
	box-shadow: inset 0 1px rgba(255, 255, 255, 0.1),
		inset 0 0 0 1px rgba(255, 255, 255, 0.08), 0 1px 2px rgba(0, 0, 0, 0.25);
	background: #3b5ca0;
	border-color: #2d477b #2d477b #263c68;
	background-image: -webkit-linear-gradient(top, #4369b6, #3b5ca0 66%, #365391);
	background-image: -moz-linear-gradient(top, #4369b6, #3b5ca0 66%, #365391);
	background-image: -o-linear-gradient(top, #4369b6, #3b5ca0 66%, #365391);
	background-image: linear-gradient(to bottom, #4369b6, #3b5ca0 66%, #365391);
}
.mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton:before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	background-image: -webkit-radial-gradient(
		center top,
		farthest-corner,
		rgba(255, 255, 255, 0.08),
		rgba(255, 255, 255, 0)
	);
	background-image: -moz-radial-gradient(
		center top,
		farthest-corner,
		rgba(255, 255, 255, 0.08),
		rgba(255, 255, 255, 0)
	);
	background-image: -o-radial-gradient(
		center top,
		farthest-corner,
		rgba(255, 255, 255, 0.08),
		rgba(255, 255, 255, 0)
	);
	background-image: radial-gradient(
		center top,
		farthest-corner,
		rgba(255, 255, 255, 0.08),
		rgba(255, 255, 255, 0)
	);
}
.mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton:hover:before {
	background-image: -webkit-radial-gradient(
		farthest-corner,
		rgba(255, 255, 255, 0.18),
		rgba(255, 255, 255, 0.03)
	);
	background-image: -moz-radial-gradient(
		farthest-corner,
		rgba(255, 255, 255, 0.18),
		rgba(255, 255, 255, 0.03)
	);
	background-image: -o-radial-gradient(
		farthest-corner,
		rgba(255, 255, 255, 0.18),
		rgba(255, 255, 255, 0.03)
	);
	background-image: radial-gradient(
		farthest-corner,
		rgba(255, 255, 255, 0.18),
		rgba(255, 255, 255, 0.03)
	);
}
.mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton:active {
	background: #3b5ca0;
	border-color: #263c68 #2d477b #2d477b;
	-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}
.mktoForm .mktoButtonWrap.mktoDownloadButton .mktoButton:active:before {
	content: none;
} */
