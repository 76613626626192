.home {
	/* @apply relative; */

	.is-ie & {
		opacity: 1;
		visibility: visible;
	}
}

.home__content {
	.is-ie & {
		@apply static h-auto overflow-visible;
	}
}

@screen <md {
	.section {
		@apply pt-20;

		/* .comp-m {
			@apply mt-16;
		} */

		/* .comp-s {
			@apply mt-10;
		} */
	}
}

@screen >=md {
	.section {
		@apply pt-32;

		/* .comp-m {
			@apply mt-40;
		} */

		/* .comp-s {
			@apply mt-10;
		} */
	}
}

.sec__title1 {
	@apply align-top;

	.en {
		@apply relative inline-block title1-en uppercase;

		font-family: var(--font-serif-en), serif;
		line-height: 1;
	}

	.ja {
		@apply block title3-ja;

		font-family: var(--font-serif-ja), serif;
		font-weight: 600;
		line-height: 1.2;
	}
}

.sec__title2 {
	@apply align-top;

	.en {
		@apply relative inline-block title2-en uppercase;

		font-family: var(--font-serif-en), serif;
		line-height: 1;
	}

	.ja {
		@apply block title4-ja;

		font-family: var(--font-sans-ja), sans-serif;
		font-weight: 500;
		line-height: 1.2;
	}
}

@screen >=md {
}

.sec__title3 {
	@apply align-top;

	.en {
		@apply relative inline-block title3-en uppercase;

		color: var(--cardinal-red);
		line-height: 1.2;
	}

	.ja {
		@apply block title2-ja mt-4;

		line-height: 1.7;
	}
}

.sec__title4 {
	@apply align-top;

	.en {
		@apply relative title3-en uppercase;

		color: var(--cardinal-red);
		line-height: 1.2;
	}

	.ja {
		@apply block title4-ja;

		line-height: 1.7;
	}
}

.sec__title5 {
	@apply align-top;

	.en {
		@apply relative title3-en uppercase;

		color: var(--cardinal-red);
		line-height: 1.2;
	}

	.ja {
		@apply block mt-2 title1-ja;

		font-family: var(--font-serif-en), var(--font-serif-ja), serif;
		line-height: 1;
	}
}

.sec__title6 {
	@apply text-m1;

	line-height: 1.2;
}

.sec__text {
	@apply text-m1;
}

@screen <md {
	.sec__text {
		&:nth-child(n + 2) {
			@apply mt-4;
		}
	}
}

@screen >=md {
	.sec__text {
		&:nth-child(n + 2) {
			@apply mt-8;
		}
	}
}

.sec__note {
	@apply text-s2 mt-2;
}

.sec__list {
	@apply mt-4;
}

.sec__list__item {
	@apply relative text-m1;

	color: var(--white);
	padding-left: 12px;

	&::before {
		@apply absolute left-0 top-1/2;

		width: 5px;
		height: 5px;
		background-color: var(--light-gray);
		border-radius: 50%;
		content: '';
		transform: translateY(-50%);
	}
}

.sec__link {
	@apply relative uppercase text-left section-link-txt;

	line-height: 60px;
	order: 3;
	color: var(--font-light-color);
	background-color: var(--cardinal-red);
	transition: opacity 0.48s var(--transition_03);

	&:hover {
		@apply opacity-70;

		transition: opacity 0.48s var(--transition_03);
	}

	.view-icon {
		@apply absolute right-10 top-1/2;

		transform: translateY(-50%);
	}

	.col-link {
		@apply mx-auto;
	}
}

@screen <md {
	.sec__link {
		@apply mt-10 px-6;

		min-width: 200px;
		width: 200px;
	}
}

@screen >=md {
	.sec__link {
		@apply mt-14 px-10;

		min-width: 250px;
		width: 250px;
	}
}

.view-icon {
	width: 25px;
	height: 5px;
}

.icon-cir {
	fill: #fff;

	&:nth-child(n + 2) {
		@apply opacity-50;
	}
}

.emergency {
	@apply w-full h-full;

	min-height: 100px;
	background-color: var(--cardinal-red);
}
.emergency__wrap {
	@apply py-8;
}

@screen <md {
	.emergency__wrap {
	}
}

@screen >=md {
	.emergency__wrap {
		@apply flex;
	}
}

.emergency__title {
	@apply flex items-center;

	color: var(--white);

	.en {
		@apply title3-en uppercase;

		color: var(--white-gold);
		line-height: 1.2;
	}

	.ja {
		@apply block title3-ja;
	}
}

@screen <md {
	.emergency__title {
		@apply pb-6;

		border-bottom: 1px solid var(--white-gold);
	}
}

@screen >=md {
	.emergency__title {
		@apply pr-16;

		border-right: 1px solid var(--white-gold);
	}
}

@screen <md {
	.emergency__items {
		@apply pt-3;
	}
}

@screen >=md {
	.emergency__items {
		@apply pl-16;

		width: 70%;
	}
}

.emergency__item {
	@apply opacity-100 py-3;

	transition: opacity 0.3s var(--transition_03);
}

a.emergency__item {
	&:hover {
		@apply opacity-90;
	}
}

.emergency__date {
	@apply text-s3;

	color: var(--white-gold);
	letter-spacing: 0.2rem;
}

.emergency__text {
	@apply text-m1 mt-1;

	color: var(--white);
	line-height: 1.4;
}
