/*--------------------
  Title & Paragraph
--------------------*/
.ttl-l {
	@apply w-full;

	.ttl-l__text {
		@apply overflow-hidden;

		background-color: var(--base-darkest-color);
		color: var(--font-light-color);
	}
}

@screen <md {
	.ttl-l {
		.ttl-l__text {
			@apply py-6 px-6;
		}
	}
}

@screen >=md {
	.ttl-l {
		.ttl-l__text {
			@apply py-8 px-10;
		}
	}
}

.ttl-l__text {
	@apply text-ll tracking-wider;

	font-weight: 500;
}

.ttl-m {
	@apply w-full;

	.ttl-m__text {
		@apply box-border relative;

		background-color: var(--base-lightest-color);
		border-top: 6px solid var(--base-darkest-color);
		color: var(--font-color);
	}
}

@screen <md {
	.ttl-m {
		.ttl-m__text {
			@apply py-6 px-8;
		}
	}
}

@screen >=md {
	.ttl-m {
		.ttl-m__text {
			@apply py-6 px-10;
		}
	}
}

.ttl-m__text {
	@apply text-lm tracking-wider;

	font-weight: 500;
}

.ttl-s {
	@apply w-full;

	.ttl-s__text {
		@apply box-border relative mx-8;

		color: var(--font-color);

		&::before {
			@apply absolute top-1/2 -translate-y-1/2;

			left: -30px;
			width: 20px;
			height: 4px;
			content: '';
			background-color: var(--font-color);
		}
	}
}

.ttl-s__text {
	@apply relative text-xl tracking-widest;

	font-weight: 500;

	& + {
		.txt-m,
		.txt-s {
			@apply mt-6;
		}
	}
}

.txt-m {
	@apply text-m1 text-justify;

	/* &:nth-of-type(n + 2) { */
	p {
		@apply mt-3;

		color: var(--font-color);
	}
	/* } */
}

/* .txt-m__text {
	.txt-m & {
		p {
			color: var(--font-color);
		}
	}
} */

.txt-s {
	@apply text-s1 text-justify;

	/* &:nth-of-type(n + 2) { */
	p {
		@apply mt-3;
	}
	/* } */
}

.txt-s__text {
	.txt-s & {
		p {
			color: var(--font-color);
		}
	}
}
