.sub {
	.is-ie & {
		opacity: 1;
		visibility: visible;
	}

	.sub__content {
		@apply overflow-hidden;
	}
}

@screen <md {
	main {
		@apply pt-12;
	}
}

@screen >=md {
	main {
		@apply pt-20;
	}
}
