.kv {
	@apply relative w-full;

	background-color: var(--base-light-color);
}

@screen <md {
	.kv {
		@apply pt-24;
	}
}

@screen >=md {
	.kv {
		@apply pt-32;
	}
}

.kv__wrap {
	@apply relative w-full;
}

.kv__text {
	@apply relative z-content h-full;
}

@screen <md {
	.kv__text {
	}
}

@screen >=md {
	.kv__text {
	}
}

.kv__bg {
	@apply absolute z-bg inset-0 w-full overflow-hidden;

	/* filter: grayscale(100%) brightness(110%); */
}

@screen <md {
	.kv__bg {
		@apply h-2/3;
	}
}

@screen >=md {
	.kv__bg {
	}
}

.kv__title {
	@apply relative z-content w-full mt-16 mb-16;

	.en {
		@apply block lowercase kv-en;

		font-weight: 400;
	}

	.ja {
		@apply block kv-ja;

		color: var(--font-color);
		font-weight: 400;
	}
}

@screen <md {
	.kv__title {
		@apply text-center;

		.en {
			@apply mt-2;
		}
	}
}

@screen >=md {
	.kv__title {
		@apply flex items-center;

		.en {
			@apply ml-4;
		}
	}
}
