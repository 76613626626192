/*--------------------
  Media for Aspect Ratio and Image for
--------------------*/
.media__image {
	@apply h-full w-full object-cover object-center;

	user-select: none;
	transition: transform 1s var(--transition_01);
	/* filter: grayscale(100%); */
}
/* .media-right {
	@apply pl-8 pb-10 ml-8;
}

.media-left {
	@apply pr-8 pb-10 mr-8;
} */

@screen >=md {
	.media__image {
		/* @apply box-border; */

		/* .media-right & {
			@apply pl-10 pb-10;
		}

		.media-left & {
			@apply pr-10 pb-10;
		} */
	}
}

.col__media {
	@apply relative aspect-4d3 w-full overflow-hidden;
}

@screen <sm {
	.col__media {
		@apply h-44;
	}
}

@screen sm {
	.col__media {
		@apply h-60;
	}
}

@screen >=md {
	.col__media {
		@apply h-96;
	}
}

.col-tp__media {
	@apply relative aspect-4d3 overflow-hidden;
}

@screen <sm {
	.col-tp__media {
		@apply w-full h-44;
	}
}

@screen sm {
	.col-tp__media {
		@apply w-full h-72;
	}
}

@screen >=md {
	.col-tp__media {
		@apply w-1/2 h-80;

		&.media-right {
			@apply float-right;
		}

		&.media-left {
			@apply float-left;
		}
	}
}
/*
.col-tp__image {
	@apply h-full w-full object-cover object-center;

	transition: transform 1s var(--transition_01);
} */

.card__media {
	@apply relative aspect-4d3 overflow-hidden;
}

@screen <sm {
	.card__media {
		@apply w-full h-44;
	}
}

@screen sm {
	.card__media {
		@apply w-full h-72;
	}
}

@screen >=md {
	.card__media {
		@apply w-1/2 h-80;

		.media-left {
			order: 1;
		}

		.media-right {
			order: 2;
		}
	}
}

a.col-3__media,
a.col-1__item,
a.col-2__item,
a.col-3__item {
	.media__image {
		@apply scale-100;
		transition: transform 0.4s var(--transition_03);
	}

	&:hover {
		.media__image {
			@apply scale-105;
		}
	}
}
