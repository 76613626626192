.gallery {
	@apply relative z-content overflow-hidden;

	height: var(--tiles-height);
	background: var(--blue-grad);
}

@screen <md {
	.gallery {
		--tiles-height: 100vw;
	}
}

@screen >=md {
	.gallery {
		--tiles-height: 50vw;
	}
}

.gallery__wrap {
	@apply absolute top-1/2 left-1/2;

	width: 150%;
	--tileswrap-height: calc(var(--tiles-height) * 1.2);
	height: var(--tileswrap-height);
	transform-origin: center;
	transform: translate3d(-50%, -60%, 0) rotate(-5deg);
}

.gallery__title {
	@apply flex items-center justify-center;

	.en {
		color: var(--white);
		line-height: 1;
	}
}

.gallery__line {
	@apply flex;
}

.gallery__item {
	@apply flex-none;

	--tile-margin: 1vw;
	width: calc(16.6666% - var(--tile-margin) * 2);
	height: calc(var(--tileswrap-height) / 2 - (var(--tile-margin) / 2));
	margin: var(--tile-margin);
}

.gallery__item {
	@apply relative;

	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.gallery__media {
	@apply absolute top-0 left-0 w-full h-full overflow-hidden;
}

.gallery__image {
	@apply absolute top-0 left-0 w-full h-full;

	max-width: unset;
	pointer-events: none;
	user-select: none;
	object-fit: cover;
	transform-origin: center;
	transform: scale(1.75);
}
