/*--------------------
  Breadcrumb
--------------------*/
.b-crumb {
	@apply inline-block;
}

@screen <md {
	.b-crumb {
		@apply py-4;
	}
}

@screen >=md {
	.b-crumb {
		@apply py-4;
	}
}

.b-crumb__list {
	@apply mt-auto mr-auto leading-6;

	/* Only article page */
	.ttl-l__text + & {
		@apply mt-6;
	}
}

.b-crumb__item {
	@apply relative inline-block mr-4 mb-2 text-s3 tracking-widest;

	color: var(--font-color);
	line-height: 1.5;

	&:nth-of-type(n + 2) {
		padding-left: 20px;

		&::before {
			@apply icon-bg icon-arrow-r;
		}
	}
}

.b-crumb__link {
	transition: opacity 0.56s var(--transition_03);

	&:hover {
		@apply opacity-70;
	}
}
