/* https://css-tricks.com/a-clever-sticky-footer-technique/ */
/* html,
body {
	height: 100%;
} */

html {
	@apply fixed h-full w-full inset-0 overflow-hidden;

	hyphens: auto;
	line-break: normal;
	text-underline-position: under;
	overflow-wrap: break-word;

	/* Reset */
	text-rendering: optimizeLegibility;
	outline: none;
	/* -ms-text-size-adjust: 100%; */
	/* -webkit-text-size-adjust: 100%; */
	/* -webkit-tap-highlight-color: transparent; */
	/* -webkit-font-smoothing: antialiased; */
	/* -webkit-text-rendering: optimizeLegibility; */
	/* -webkit-appearance: none; */
	/* -moz-osx-font-smoothing: grayscale; */
}

body {
	@apply fixed h-full w-full inset-0;

	/* @include body-text; */
	background-color: var(--theme-color);
	color: var(--black);
	overscroll-behavior: none;
	font-family: var(--font-family);
	/* user-select: none; */
	/* -webkit-tap-highlight-color: transparent; */
	/* -webkit-font-smoothing: antialiased; */
	/* cursor: none; */
}

button,
/* input, */
optgroup,
select,
textarea {
	font: inherit;
	letter-spacing: inherit;
}

select {
	appearance: none;
}

svg {
	max-width: 100%;
	height: auto;
}

ul {
	list-style-type: none;
}

a {
	display: block;

	/* Reset */
	text-decoration: none;
	outline: none;
	-webkit-outline: none;
	-webkit-tap-highlight-color: transparent;

	&:active,
	&:hover {
		outline: 0;
	}

	/* &:visited {
		@apply text-gray-700;
	} */
}

img {
	user-drag: none;
	-webkit-user-drag: none;
	-moz-user-select: none;

	transition: opacity 0.3s ease;

	&.loaded {
		@apply opacity-100;
	}
}

button {
	appearance: none;
	background: none;
	border: none;
	outline: none;
	-webkit-tap-highlight-color: transparent;
}

.content {
	@apply relative z-content w-full;
}

.modal {
	@apply fixed z-modal inset-0 w-full h-full invisible overflow-hidden pointer-events-none;

	&.is-active {
		@apply visible pointer-events-auto;
	}
}

.wipe {
	@apply fixed h-full w-full inset-0 z-wipe overflow-hidden pointer-events-none will-change-transform;

	transform: translateY(100%);
	/* background: var(--royal-blue); */
}

.js-lazy,
.js-preload {
	@apply opacity-0;
}

/* https://github.com/WICG/focus-visible#2-update-your-css */
.js-focus-visible :focus:not(.focus-visible) {
	outline: none;
}

/* SNS */
.sns-link {
	transition: opacity 0.56s var(--transition_03);

	&:hover {
		@apply opacity-70;
	}
}

.sns-image {
	width: 26px;
	height: 26px;
}

/*--------------------
  Container & Wrapper
--------------------*/
/* Top Margin for containers */
/* @screen <md {
	.cont-m {
		main > & {
			&:not(:first-child) {
				@apply mt-16;
			}
		}
	}
}

@screen >=md {
	.cont-m {
		main > & {
			&:not(:first-child) {
				@apply mt-20;
			}
		}
	}
} */

/* Top margin for components */
/* .comp-l,
.comp-m,
.comp-s {
	@apply w-full;
} */

@screen <md {
	.comp-l {
		article > &,
		main > & {
			&:nth-child(n + 2) {
				@apply mt-16;
			}
		}
	}
}

@screen >=md {
	.comp-l {
		article > &,
		main > & {
			&:nth-child(n + 2) {
				@apply mt-32;
			}
		}
	}
}

@screen <md {
	.comp-m {
		article > &,
		main > & {
			&:nth-child(n + 2) {
				@apply mt-16;
			}
		}
	}
}

@screen >=md {
	.comp-m {
		article > &,
		main > & {
			&:nth-child(n + 2) {
				/* @apply mt-24; */
				@apply mt-28;
			}
		}
	}
}

@screen <md {
	.comp-s {
		&:nth-child(n + 2) {
			@apply mt-8;
		}
	}
}

@screen >=md {
	.comp-s {
		&:nth-child(n + 2) {
			/* @apply mt-12; */
			@apply mt-14;
		}
	}
}

/* Max width for containers */
/* @screen <md {
	.wrap-l {
		@apply w-full px-6;
	}
}

@screen >=md {
	.wrap-l {
		@apply box-border mx-auto px-10;

		max-width: 1440px;
	}
} */

.wrap-full {
	@apply w-full;
}

@screen <md {
	.wrap-full {
		@apply px-6;
	}
}

@screen >=md {
	.wrap-full {
		@apply px-14;
	}
}

@screen <md {
	.wrap-m {
		@apply w-full px-6;
	}
}

@screen >=md {
	.wrap-m {
		@apply mx-auto px-14;

		max-width: 1440px;
	}
}

@screen <md {
	.wrap-s {
		@apply w-full px-10;
	}
}

@screen >=md {
	.wrap-s {
		@apply mx-auto px-24;

		max-width: 1440px;
	}
}

@screen <md {
	.bg-image {
		@apply h-full w-full object-cover;
		object-position: right 20% bottom 0%;
	}
}

@screen >=md {
	.bg-image {
		@apply h-full w-full object-cover object-center;
	}
}

.modal-box {
	@apply fixed z-modal inset-0 flex items-center justify-center invisible;

	/* &.is-active {
		@apply visible;
	} */
}

.modal-box-bg {
	@apply absolute z-bg inset-0 w-full h-full;

	background-color: rgba(0, 0, 0, 0.2);
}

.gcse-search {
	/* @apply invisible;

	.search-modal.is-active & {
		@apply visible;
	} */
}

.search-modal__cont {
	@apply relative z-content;

	background-color: var(--white);
}

@screen <md {
	.search-modal__cont {
		@apply w-5/6;
	}
}

@screen >=md {
	.search-modal__cont {
		@apply w-4/6;
	}
}

.search-modal__scrollable {
	@apply w-full invisible;

	overflow-y: auto;
	transition: min-height 0.3s var(--transition_01);
}

@screen <md {
	.search-modal__scrollable {
		min-height: 110px;
	}
}

@screen >=md {
	.search-modal__scrollable {
		min-height: 160px;
	}
}

@screen <md {
	.search-modal__scrollable {
		padding: 30px;
		max-height: 80vh;
	}
}

@screen >=md {
	.search-modal__scrollable {
		padding: 50px;
		max-height: 70vh;
	}
}

.search-modal__close {
	@apply hidden absolute top-0 right-0 cursor-pointer;

	width: 48px;
	height: 48px;
	background-color: var(--black);
}
/*
.search-cse {
	@apply w-full h-full;
} */
/*
.search-form {
	position: relative;

	.search-input {
		background: rgba(#aaabd2, 0.17);
		border: none;
		width: 270px;
		height: 48px;
		color: #7c7eba;
		padding: 0 40px 0 10px;

		&::placeholder {
			line-height: 1.85;
			letter-spacing: 0.05em;
			color: #aaaaaa;
		}
	}
}

.search-input {
	@apply h-full;

	background-color: var(--light-gray);
}

.search-btn {
	width: 48px;
	height: 48px;
	border: none;
	background: var(--cardinal-red);
	vertical-align: middle;
	position: relative;
	&::before {
		position: absolute;
		content: '\f002';
		cursor: pointer;
		top: 50%;
		transform: translateY(-50%);
		left: -2em;
		color: #ddd;
	}
} */
/*
.search-result {
	@apply w-full mt-10;

	min-height: 50vh;
} */

/* Search resutl */
.gsc-results-wrapper-nooverlay {
	margin-top: 40px !important;
}

.gsc-control-cse {
	height: 48px !important;
	margin: 0px !important;
	padding: 0px !important;
}

.gsc-search-box {
	margin: 0px !important;
}

/* Space inbetween input and search button */
.gsc-input {
	padding-right: 0 !important;
	background-color: var(--light-gray) !important;

	font-family: var(--font-sans-ja), sans-serif;
}

.gsc-input-box {
	border: none !important;
	padding: 0 !important;
	border-radius: 0 !important;
}

.gsib_a {
	padding-left: 20px !important;

	input {
		background-color: var(--light-gray) !important;
	}
}

.gsib_b {
	background-color: var(--light-gray) !important;

	.gsst_a {
		padding: 0 5px !important;

		.gscb_a {
			line-height: 48px !important;
			color: var(--black) !important;
		}
	}

	/* Delete button */
	.gsst_b {
		padding: 0 10px !important;
	}
}

.gsc-search-button-v2 {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	height: 48px !important;
	margin: 0px !important;
	padding-top: 12px !important;
	padding-bottom: 13px !important;
	padding-right: 14px !important;
	padding-left: 14px !important;

	background-color: var(--cardinal-red) !important;
	color: #4990c8 !important;
	border-top-style: none !important;
	border-right-style: none !important;
	border-bottom-style: none !important;
	border-left-style: none !important;
	border-radius: 0 !important;
	cursor: pointer;
	transition: opacity 0.3s var(--transition_03);

	&:hover {
		@apply opacity-80;
	}
}

@screen <md {
	.gsc-search-button-v2 {
		width: 48px !important;
	}
}

@screen >=md {
	.gsc-search-button-v2 {
		width: 108px !important;
	}
}

.gsc-search-button-v2 svg {
	fill: var(--white) !important;
	width: 15px;
	height: 15px;
}
