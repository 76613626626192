@screen <md {
	.scroll {
		@apply hidden;
	}
}

@screen >=md {
	.scroll {
		@apply absolute z-bar top-0 right-0 opacity-0 invisible;

		width: 4px;
		height: 100vh;
		/* background-color:var(--font-color); */
		background-color: var(--base-light-color);
		transition: visibility 0.3s var(--transition_03) 1s,
			opacity 0.3s var(--transition_03) 1s;

		&.is-active {
			@apply opacity-100 visible;

			transition: visibility 0.3s var(--transition_03),
				opacity 0.3s var(--transition_03);
		}
	}
}

.scroll__bar {
	@apply relative w-full cursor-pointer;

	background-color: var(--cardinal-red);
	height: 10vh;

	&.is-dragging {
		cursor: grabbing;
	}
}
