/*--------------------
  Split text
--------------------*/
.title__letter--main,
.title__letter--sub {
	display: inline-block;
}

.title__letter--line {
	display: inline-block;
}

/*--------------------
  Hide animation elems
--------------------*/
.page-transition {
	@apply relative inset-0 h-full w-full overflow-hidden;

	/* Cause flicers on Chrome */
	/* will-change: transform; */
}

.cont-transform {
	@apply inset-0 h-full w-full overflow-hidden will-change-transform;

	background-color: var(--theme-color);
}

/* .transition, */
.preloader__percent,
.preloader__text,
.preloader__percent__text,
.preloader__bar,

.header__logo,
.header__bar,
.header__language__links,
.header__corporate__links,
.header__nav__pc,
/* .nav__modal, */
.nav__trigger,
.nav__modal__main,
.nav__modal__sub,
.logo,
/* .slide__image, */
/* .slider__description, */
/* .scroll__indicator, */
[data-animation='slide-in'] {
	@apply opacity-0 invisible;

	.is-ie & {
		@apply visible;
	}
}

.nav__modal {
	@apply opacity-0 invisible;
	/* @apply pointer-events-none; */

	.is-ie & {
		@apply visible;
	}
}

@screen md {
	.slider__title__text--main,
	.slider__title__text--sub {
		@apply opacity-0 invisible;

		.is-ie & {
			@apply visible;
		}
	}
}

/*--------------------
  Icon
--------------------*/

.icon-more {
	width: 48px;
	height: 48px;
	transform: rotate(-90deg);
}

.icon-more__cir {
	fill: transparent;
	stroke: var(--font-color);
	stroke-width: 1;

	.hero__updates:hover & {
		animation: animate-cir 0.48s var(--transition_03) forwards;
	}
}

.icon-more__arr {
	fill: var(--font-color);
	transform-origin: center;
	transform: scale(1.4);

	.hero__updates:hover & {
		animation: animate-arr 0.48s var(--transition_03) forwards;
	}
}

@keyframes animate-cir {
	0% {
		stroke-dasharray: 0 195;
	}
	99.9%,
	to {
		stroke-dasharray: 195 195;
	}
}

@keyframes animate-arr {
	0% {
		transform: scale(1.4);
	}
	50% {
		opacity: 0;
		transform: translateY(5px) scale(0);
	}
	51% {
		transform: translateY(-4px);
	}
	100% {
		opacity: 1;
		transform: translateY(0) scale(1.4);
	}
}

/*--------------------
  Hover
--------------------*/
.hover-fade {
	transition: opacity 0.56s var(--transition_03);

	&:hover {
		@apply opacity-70;
		transition: opacity 0.56s var(--transition_03);
	}
}

.hover-wipe {
	/* @apply relative; */

	&::after {
		@apply absolute z-bg2 inset-0 w-full h-full scale-y-0 origin-top;

		/* background-color: var(--royal-blue); */
		content: '';
		transition: transform 0.2s var(--transition_03);
	}

	&:hover {
		&::after {
			@apply scale-y-100 origin-bottom;
		}
	}
}

.hover-wipe-active {
	/* @apply relative; */

	&::before {
		@apply absolute z-bg inset-0 w-full h-full scale-y-0 origin-bottom;

		content: '';
		transition: transform 0.2s var(--transition_03);
	}

	&:hover,
	&.is-active {
		&::before {
			@apply scale-y-100 origin-top;
		}
	}
}

.hover-wipe-side {
	/* @apply relative; */

	&::after {
		@apply absolute inset-0 w-full h-full scale-x-0 origin-right;

		background-color: var(--royal-blue);
		content: '';
		transition: transform 0.2s var(--transition_03);
	}

	&:hover {
		&::after {
			@apply scale-x-100 origin-left;

			transition: transform 0.2s var(--transition_03);
		}
	}
}

/*--------------------
  Navigation
--------------------*/

@keyframes hover-scale {
	0% {
		transform: translateY(-50%) scale(1);
	}
	/* 50% {
		transform: translateY(-50%) scale(1.1);
	} */
	100% {
		transform: translateY(-50%) scale(1.1);
	}
}

/*
@keyframes scroll-drop {
	0% {
		transform: translateY(0);
	}
	10% {
		opacity: 1;
	}
	80% {
		transform: translateY(40px);
		opacity: 0;
	}
	81% {
		opacity: 0;
	}
	100% {
		transform: translateY(0);
	}
} */

/*
@keyframes link-hover-bottom {
	0% {
		transform: translateY(100%);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-100%);
	}
}
@keyframes link-hover-left {
	0% {
		transform: translateX(-100%);
	}
	50% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}

@keyframes line {
	0% {
		transform: translateX(0);
	}
	30% {
		transform: translateX(100%);
	}
	31% {
		visibility: none;
		transform: translateX(-200%);
	}
	32% {
		visibility: visible;
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes scroll-drop {
	0% {
		transform: translateY(0);
	}
	10% {
		opacity: 1;
	}
	80% {
		transform: translateY(40px);
		opacity: 0;
	}
	81% {
		opacity: 0;
	}
	100% {
		transform: translateY(0);
	}
}
 */
@keyframes hover-mask-wrapper {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes hover-mask {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

.hover-rotate-wrap {
	@apply block relative z-content overflow-hidden;

	line-height: 1;
}

@keyframes hover-rotate {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	30% {
		transform: translateY(-100%);
		opacity: 1;
	}
	31% {
		opacity: 0;
	}
	70% {
		opacity: 0;
		transform: translateY(100%);
	}
	71% {
		opacity: 1;
	}
	100% {
		transform: translateY(0);
	}
}
