.social {
}
@screen <md {
	.social {
		@apply pb-20;
	}
}

@screen >=md {
	.social {
		@apply pb-32;
	}
}

.social__wrap {
}

.social__title {
	@apply text-center;
}

@screen <md {
	.social__col {
		@apply mt-8 px-4;
	}
}

@screen >=md {
	.social__col {
		@apply grid gap-16 justify-center mt-20;

		grid-template:
			'fb tw' 60vh
			'snsA snsB' 100px
			/ 500px 500px;

		grid-template-columns: minmax(10%, 500px) minmax(10%, 500px);
	}
}

.social__col__item {
	max-width: 500px;
}

@screen <md {
	.social__col__item {
		@apply mx-auto;

		&:nth-child(n + 2):nth-child(-n + 3) {
			@apply mt-16;
		}
	}
}

.facebook,
.twitter {
	@apply w-full overflow-hidden;

	width: 100%;
}

@screen <md {
	.facebook,
	.twitter {
		height: 40vh;
	}
}

@screen >=md {
	.facebook,
	.twitter {
		height: 60vh;
	}
}

.facebook {
	grid-area: fb;
}

.twitter {
	@apply overflow-auto;

	grid-area: tw;
}

.social__sns__title {
	font-weight: 500;
	min-width: 120px;
}

.sns {
	@apply flex items-center w-full h-full;

	color: var(--white);
}

@screen <md {
	.sns {
		@apply px-4;

		height: 60px;
	}
}

@screen >=md {
	.sns {
		@apply px-10;

		height: 100px;
	}
}

@screen sp-land {
	.sns {
		@apply px-6;
	}
}

.abs {
	grid-area: snsA;
	background-color: var(--midnight-blue);
}

.alumni {
	grid-area: snsB;
	background-color: var(--cardinal-red);
}

@screen <md {
	.alumni {
		@apply mt-6;
	}
}

.social__sns {
	@apply relative flex items-center justify-center;

	.icon-fb {
		fill: var(--white);
	}

	.icon-tw {
		fill: var(--white);
	}

	.icon-yt {
		fill: var(--white);
	}

	&::before {
		@apply block absolute top-1/2 left-0 opacity-30;

		width: 1px;
		height: 22px;
		content: '';
		background-color: var(--white);
		transform: translateY(-50%);
	}
}

@screen <md {
	.social__sns {
		@apply ml-2 pl-1;
	}
}

@screen >=md {
	.social__sns {
		@apply ml-10 pl-8;
	}
}

@screen sp-land {
	.social__sns {
		@apply ml-3 pl-2;
	}
}

.social__sns__item__link {
	@apply flex items-center justify-center;

	width: 48px;
	height: 48px;
}
