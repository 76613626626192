.cursor {
	@apply opacity-0 invisible;

	transition: opacity 0.6s var(--transition_03),
		visibility 0.6s var(--transition_03);

	&.is-link {
		@apply opacity-100 visible;
	}
}

@screen <md {
	.cursor {
		display: none;
	}
}

@screen >=md {
	.cursor {
		@apply absolute z-header pointer-events-none;

		width: 120px;
		height: 120px;
		top: 0;
		left: 0;
		/* mix-blend-mode: difference; */
		/* visibility: hidden; */
	}
}

.cursor__bg {
	box-sizing: border-box;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--white);
	/* border: solid 3px var(--base-darkest-color); */
	border-radius: 50%;
	/* visibility: hidden;
	transition: opacity 0.6s var(--transition_03),
		visibility 0.6s var(--transition_03);

	.is-link & {
		opacity: 0.5;
		visibility: visible;
	} */
}

.icon__next,
.icon__prev {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: opacity 0.3s var(--transition_03),
		visibility 0.3s var(--transition_03);

	&.is-active {
		opacity: 1;
		visibility: visible;
	}
}

.icon__next {
	transform-origin: center center;
	/* transform: rotate(90deg); */
}

.icon__next__wrapper,
.icon__prev__wrapper {
	position: relative;
	z-index: 1;
	width: 12px;
	height: 22px;
}

@screen <md {
	.icon__next__wrapper,
	.icon__prev__wrapper {
		display: none;
	}
}

.icon__arrow {
	fill: none;
	stroke: #fff;
	stroke-linecap: round;
	stroke-miterlimit: 10;
	fill-rule: evenodd;
}
