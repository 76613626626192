.links {
	background-color: var(--isabelline);
}

@screen <md {
	.links {
		@apply pb-20;
	}
}

@screen >=md {
	.links {
		@apply pb-32;
	}
}

.links__col {
	@apply grid  gap-8;
}

@screen <md {
	.links__col {
		@apply mt-10 grid-cols-2;
	}
}

@screen md {
	.links__col {
		@apply mt-12 grid-cols-4;
		order: 2;
	}
}

@screen >=lg {
	.links__col {
		@apply mt-12 grid-cols-4;
	}
}

.links__col__item {
	@apply w-full opacity-100;

	transition: opacity 0.3s var(--transition_03);

	&:hover {
		@apply opacity-80;
	}
}

@screen <sm {
	.links__col__item {
		height: 6rem;
	}
}

@screen sm {
	.links__col__item {
		height: 10rem;
	}
}

@screen md {
	.links__col__item {
		height: 8rem;
	}
}

@screen >=lg {
	.links__col__item {
		height: 10rem;
	}
}

.links__image {
	@apply w-full;
}
