@screen <md {
	.news__filter {
		text-align: center;
		margin-top: 10rem;
	}
}

@screen >=md {
	.news__filter {
		display: flex;
	}
}

.news__filter__btn {
	display: inline-block;
	position: relative;
	min-width: 40px;
	padding: 0 20px;
	color: $black;
	letter-spacing: 0.1rem;
	transition: color 0.48s var(--transition_03);
	cursor: pointer;

	&:hover,
	&.is-active {
		color: $vermilion;
	}

	&:not(:last-child) {
		&::before {
			position: absolute;
			top: 0;
			right: -1px;
			width: 2px;
			height: 100%;
			color: $black;
			opacity: 0.3;
			content: '|';
		}
	}
}

@screen <md {
	.news__filter__btn {
		line-height: 28px;
	}
}

@screen >=md {
	.news__filter__btn {
		line-height: 48px;
	}
}

.news__item {
	display: none;

	&.is-show {
		display: flex;
	}
}
