/*--------------------
Default Size
（画面幅750pxの場合、1.6remが16px相当）
--------------------*/
/* @screen <md {
	html {
		font-size: calc(100vw / 750 * 10);
	}
}
@screen md {
	html {
		font-size: calc(100vw / 1024 * 10);
	}
}
@screen lg {
	html {
		font-size: calc(100vw / 1440 * 10);
	}
}
@screen xl {
	html {
		font-size: calc(100vw / 1920 * 10);
	}
} */

/* ブレークポイント毎（固定サイズ）の場合
  SP
 $font_size-sp: 62.5%;
  TABLET
 $font_size-tablet: 62.5%;
  PC
 $font_size-pc: 62.5%; */

/*--------------------
Font Face
--------------------*/
:root {
	--font-family: 'Open Sans', 'Arial', 'Helvetica Neue', 'Helvetica',
		'Noto Sans', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3',
		'Yu Gothic Medium', '游ゴシック Medium', 'YuGothic', '游ゴシック体',
		'Meiryo', 'メイリオ', sans-serif;
	--font-serif-en: 'Cormorant Garamond';
	--font-sans-en: 'Open Sans';
	--font-serif-ja: 'Noto Serif';
	--font-sans-ja: 'Noto Sans';
	--font-num: 'Cinzel';
}

@font-face {
	font-family: 'Cormorant Garamond';
	src: resolve('cormorant-garamond_medium.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: resolve('open-sans_regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: resolve('open-sans_medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: resolve('open-sans_semi-bold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: resolve('open-sans_bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Serif';
	src: resolve('noto-serif_semi-bold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: '';
	src: url('NotoSerifJP-SemiBold-Alphabetic.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: resolve('noto-sans_regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: resolve('noto-sans_medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: resolve('noto-sans_bold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Cinzel';
	src: resolve('cinzel_regular.woff2') format('woff2');
	font-weight: 400;
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
