/*--------------------
  Webgl
--------------------*/
.carousel {
	@apply relative w-full cursor-grab;

	height: 40vh;

	&.is-dragging {
		cursor: grabbing;
	}
}

.carousel__wrapper {
	@apply w-full h-full;
}

.carousel__item {
	@apply box-border absolute top-0 left-0 overflow-hidden;
}

@screen <md {
	.carousel__item {
		width: calc(100vw / 2);
		height: 60vw;
	}
}

@screen >=md {
	.carousel__item {
		@apply h-full overflow-hidden;

		width: calc(100vw / 3);
	}
}

.carousel__media {
	@apply absolute w-full h-full p-4;
	background-color: var(--theme-color);
}
