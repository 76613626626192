/* .col__aside {
	@apply will-change-transform;
} */

.side-nav__title {
	@apply py-4 px-6 text-m1 uppercase tracking-widest;

	background-color: var(--base-light-color);
	color: var(--font-color);
	border-top: 4px solid var(--gainsboro);
	font-weight: 500;
	letter-spacing: 0.1rem;

	&:nth-of-type(n + 2) {
		@apply comp-s;
	}
}

.side-nav__list {
	@apply p-6;
}

.article__text {
	@apply relative z-content w-full h-full py-10;
}

.article__date__text {
	@apply text-m1 mb-2;

	color: var(--font-color);
}

.article__date {
	@apply relative z-content;

	min-width: 100px;
	width: 100px;
}

.article__title {
	@apply relative z-content kv-ja mt-6;

	color: var(--font-color);
}

.article__tag {
	@apply relative z-content mt-5;
}

.category__list__item,
.tag__list__item {
	@apply inline-block mb-4 mr-4 px-3 py-2 uppercase text-s3 tracking-wider;

	transition: opacity 0.56s var(--transition_03),
		background-color 0.56s var(--transition_03);

	&:hover {
		@apply opacity-80;
	}

	.item__num {
		font-size: 80%;
	}
}

.archives__list__item {
	@apply mb-4 text-s1 tracking-wider;

	transition: opacity 0.56s var(--transition_03);

	&:hover {
		@apply opacity-80;
	}

	.item__num {
		font-size: 60%;
	}
}

.item__name,
.item__num {
	@apply inline-block;
}

.item__num {
	@apply relative mt-2 ml-1 align-top;
}

.category__list__item {
	color: var(--font-light-color);
	background-color: var(--base-darkest-color);
}

.tag__list__item {
	@apply inline-block mb-4 mr-4 px-3 py-2 rounded-sm uppercase text-s3 tracking-widest;

	border: 1px solid var(--base-dark-color);

	&:hover {
		@apply opacity-80;
	}
}

.main-col {
	@apply grid;

	.wrap-s {
		@apply p-0;
	}
}

@screen <md {
	.main-col {
		@apply grid-cols-1 gap-16;
	}
}

@screen >=md {
	.main-col {
		@apply grid-cols-3 gap-20;
	}

	.col__article {
		@apply col-start-1 col-span-2;
	}
}

.share__link {
	@apply inline-block mr-6 mb-4;

	color: var(--font-color);
}

.article {
	.category-list {
		@apply mt-20;
	}
}
