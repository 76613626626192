.preloader {
	@apply fixed z-loader h-full w-full top-0 left-0;

	background-color: var(--black);
}
/*
.preloader__text {
	@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl font-normal tracking-wide text-center uppercase whitespace-nowrap;

	color: var(--font-color);
}

.preloader__percent {
	@apply absolute bottom-20 left-1/2 -translate-x-1/2;

	color: var(--font-color);
}

.preloader__bar {
	@apply hidden absolute -translate-x-1/2 -translate-y-1/2;

	top: 54%;
	left: 50%;
	width: 8vw;
	height: 2px;
}

.preloader__bar__state {
	@apply h-full w-full;
	width: 100%;
	height: 100%;
	transition: transform 0.3s ease;
}

.preloader__percent__text {
} */
