.info__category__list {
	@apply flex mb-2;
}

.info__category__list__item {
	@apply relative inline-block mb-4 mr-4 px-4 py-2 uppercase text-s2 tracking-wider;

	color: var(--font-light-color);
	background-color: var(--base-dark-color);
	transition: opacity 0.3s var(--transition_03);

	&.is-active,
	&:hover {
		@apply opacity-70;
		transition: opacity 0.3s var(--transition_03);
	}

	&::before {
		@apply absolute z-bg top-0 left-0;

		width: 100%;
		height: 100%;
		content: '';
		background: linear-gradient(
			-45deg,
			var(--base-dark-color) 0 6px,
			transparent 6px
		);
	}

	.item__num {
		font-size: 80%;
	}
}

.category__list__link {
	@apply relative z-content;
}

/*
@screen <md {
	.footer__media {
		height: 20rem;
	}
}

@screen >=md {
	.footer__media {
		height: 30rem;
	}
} */
