/*--------------------
Progress Bar
--------------------*/
.progress__bar {
	@apply absolute top-0 left-0 h-full w-full bg-gray-200;
}

.progress__state {
	@apply h-full w-full bg-gray-800;

	transform: scaleY(0);
	transform-origin: 0% 0%;
}

@screen <md {
	.progress {
		@apply hidden;
	}
}

@screen >=md {
	.progress {
		@apply fixed z-bar opacity-0;
		width: 4px;
		height: 10vh;
		/* height: calc(100vh - 65px); */
		/* top: 50%; */
		/* top: 0; */
		bottom: 4rem;
		right: 2rem;
		/* transform: translateY(-50%); */
	}
}
