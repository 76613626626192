.tabs {
	@apply flex;
}

.tab {
	@apply relative uppercase cursor-pointer;

	color: var(--font-color);
	-webkit-tap-highlight-color: transparent;

	/* &.is-active {
		@apply opacity-100;

		transition: opacity 0.48s var(--transition_03);
	} */

	/* &:nth-child(n + 2) {
		@apply relative;

		&::before {
			@apply absolute top-1/2 left-0 -translate-y-1/2;

			width: 1px;
			height: 60%;
			content: '';
			background-color: var(--base-dark-color);
		}
	} */
}

.tabs-cont {
	@apply relative;
}

.tab-cont {
	@apply absolute top-0 left-0 hidden;

	animation: fade-out 0.8s var(--transition_03);

	&.is-active {
		/* @apply block; */
		@apply relative grid;

		animation: fade-in 0.8s var(--transition_03);
	}

	/* &[data-category='race'] {
		@apply bg-gray-200;
	}
	&[data-category='education'] {
		@apply bg-gray-300;
	}
	&[data-category='sponsor'] {
		@apply bg-gray-400;
	}
	&[data-category='media'] {
		@apply bg-gray-500;
	} */
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
