/* For JS Pagination */
.pagination {
	@apply flex items-center justify-center mt-16;

	&.is-hide {
		@apply hidden;
	}
}

.pagination__item {
	@apply block mx-2 py-2 px-4 rounded-sm uppercase tracking-wide;

	color: var(--font-color);
	font-size: 1.2rem;
	letter-spacing: 0.2rem;

	&.is-hide {
		@apply hidden;

		animation: fade-out 0.8s var(--transition_03);
	}

	&.is-active,
	&:hover {
		background-color: var(--base-light-color);
		transition: background-color 0.48s var(--transition_03);
	}
}

.pagination__first,
.pagination__last {
	@apply block py-2 px-4 rounded-sm uppercase tracking-wide;

	color: var(--font-color);
	font-size: 1rem;
	font-weight: 600;
}

.pagination__prev,
.pagination__next {
	@apply block px-4 uppercase tracking-wide cursor-pointer;

	color: var(--font-color);
}

.pagination__eclipse__prev,
.pagination__eclipse__next {
	@apply block;

	color: var(--font-color);
	&.is-hide {
		@apply hidden;

		animation: fade-out 0.8s var(--transition_03);
	}
}

.pagination__eclipse__prev,
.pagination__eclipse__next {
	@apply px-4;

	line-height: 100%;
}

.pagination__eclipse__prev {
	@apply ml-4;
}

.pagination__eclipse__next {
	@apply mr-4;
}

/* For 11ty Pagination */
.pager__list {
	@apply flex items-center justify-center;
}

/* .pager__item {
	@apply mx-2;
} */

/* @screen <md {
	.pager__item {
		.is-active,
		.is-next {
			width: 40%;
			height: 50px;
		}
	}
} */

.pager__link {
	@apply block py-2 px-4 uppercase tracking-wide;

	color: var(--font-color);
	transition: background-color 0.48s var(--transition_03);

	&:not(.is-active):hover {
		transition: background-color 0.48s var(--transition_03);
	}

	&.is-active {
		color: var(--font-light-color);
		background-color: var(--base-dark-color);
	}
}

.pager__prev,
.pager__next {
	@apply block py-2 px-4 rounded-sm uppercase tracking-wide;

	color: var(--font-color);
	font-size: 1rem;
	letter-spacing: 0.2rem;
}
