/*--------------------
  Column - one / two / three
--------------------*/

.col-1__item,
.col-2__item,
.col-3__item {
	@apply relative;
}

@screen <md {
	.col__item,
	.col-1__item,
	.col-2__item,
	.col-3__item {
		&:nth-child(n + 2) {
			@apply mt-10;
		}
	}
}

@screen >=md {
	.col-1 {
		@apply grid grid-cols-1 gap-8;
	}
}

@screen >=md {
	.col-2 {
		@apply grid grid-cols-2 gap-8;
	}
}

@screen >=md {
	.col-3 {
		@apply grid grid-cols-3 gap-8;
	}
}

.col__caption {
	@apply absolute bottom-0 right-0 p-6 bg-gray-50 w-2/3 text-s1;

	a.col-1__item &,
	a.col-2__item &,
	a.col-3__item & {
		border-top: 6px solid var(--base-darkest-color);
	}

	.col-1__item &,
	.col-2__item &,
	.col-3__item & {
		border-top: 6px solid var(--base-dark-color);
	}

	z-index: 1;
}

/*--------------------
  Column - Text & Photo
--------------------*/

@screen <md {
	.col-tp {
		&:not(:first-child) {
			@apply mt-12;
		}

		.ttl-s__text {
			&:not(:first-child) {
				@apply mt-16;
			}
		}
	}
}

@screen >=md {
	.col-tp {
		&::after {
			@apply block clear-both;

			content: '';
		}
	}
}

.col-tp__text {
	@apply w-full;

	color: var(--font-color);
}

@screen <md {
	.col-tp__text {
		@apply mt-8;
	}
}

@screen >=md {
	.col-tp__text {
		&.txt-right {
			@apply w-1/2 float-right pl-8;
		}

		&.txt-left {
			@apply w-1/2 float-left pr-8;
		}
	}
}

@screen >=md {
	.col-tp {
		&::after {
			display: block;
			clear: both;
			content: '';
		}
		/* &:not(:first-child) {
			margin-top: 10rem;
		} */
		.media-left {
			@apply pr-8;
		}

		.media-right {
			@apply pl-8;
		}
	}
}

.col-tp-card {
	@apply relative overflow-hidden;
}

.card__text {
	background-color: var(--base-lightest-color);
}

@screen <md {
	.card__text {
		@apply w-full p-8;

		border-bottom: 6px solid;
	}
}

@screen >=md {
	.col-tp-card {
		.card__media {
			@apply absolute z-bg;
		}

		.media-left {
			@apply top-0 left-0 pr-8;
		}

		.media-right {
			@apply top-0 right-0 pl-8;
		}

		.txt-left {
			@apply mr-auto;
		}

		.txt-right {
			@apply ml-auto;
		}
	}

	.card__text {
		@apply relative z-content mt-20 py-16 px-12;

		width: calc(60%);
		border-top: 6px solid;
	}
}

/*--------------------
  2 Column - Text & Photo
--------------------*/

.col-3 {
}

@screen <md {
	.col-3 {
		&:not(:first-child) {
			@apply mt-12;
		}
	}
}

@screen >=md {
	.col-3 {
		&:not(:first-child) {
			@apply mt-24;
		}
	}
}

@screen <md {
	.col-3__wrapper {
		@apply box-border w-full px-8;
	}
}

@screen >=md {
	.col-3__wrapper {
		@apply grid grid-cols-3 gap-8 max-w-6xl mx-auto px-20;

		/* display: grid; */
		/* grid-template-columns: 1fr 1fr 1fr; */
		&:not(:first-child) {
			margin-top: 10rem;
		}
	}
}

.card {
	@apply rounded-md overflow-hidden;

	background-color: var(--base-lightest-color);

	.col-3__media {
		border-bottom: 6px solid;
	}
}

.label__link {
	@apply absolute -top-16 right-8 inline-block py-2 px-4 uppercase tracking-widest rounded-full;

	background-color: var(--base-dark-color);
	color: var(--font-color);
	font-size: 0.9rem;
	font-weight: 600;
}

.col-3__media {
	@apply relative aspect-4d3 w-full overflow-hidden;
}

@screen <md {
	.col-3__media {
		@apply h-48;
	}
}

@screen >=md {
	.col-3__media {
		@apply h-52;
	}
}

.col-3__text {
	@apply relative p-8;
}

.card-txt {
	@apply flex items-center p-8;

	font-weight: 600;

	&.solid {
		color: var(--font-light-color);
		background-color: var(--base-darker-color);
	}

	&.line {
		@apply box-border;

		color: var(--font-light-color);
		border: 6px solid var(--base-darker-color);
		background-color: var(--base-dark-color);
	}
}

.col-3__link {
	@apply relative flex flex-wrap py-8 pl-8 text-s1;

	padding-right: 40px;
	background-color: var(--base-darker-color);
	color: var(--font-light-color);
	font-weight: 600;

	&::after {
		@apply icon-bg icon-link-anchor;
	}
}

@screen <md {
	.col-3__link {
		@apply mt-10;
	}
}

/* @screen <md {
	.box-txt {
		@apply py-10;
	}
}

@screen >=md {
	.box-txt {
		@apply py-16;
	}
} */

.txt__item {
	@apply p-8 overflow-hidden;

	border-top: 6px solid var(--base-dark-color);
	background-color: var(--base-lightest-color);
}

@screen <md {
	.txt__item {
		&:not(:first-child) {
			margin-top: 2rem;
		}
	}
}
