@screen <md {
	.list {
		&:not(:first-child) {
			@apply mt-6;
		}
	}
}

@screen >=md {
	.list {
		&:not(:first-child) {
			@apply mt-10;
		}
	}
}

.list-num {
	counter-reset: li;
}

.list-dis__item,
.list-num__item {
	@apply relative block text-s1;

	color: var(--font-color);

	.list__link {
		@apply block;

		color: var(--font-link-color);
		transition: opacity 0.56s var(--transition_03);

		&:hover {
			@apply opacity-80;
		}
	}
}

@screen <md {
	.list-dis__item,
	.list-num__item {
		&:not(:first-child) {
			@apply mt-2;
		}
	}
}

@screen >=md {
	.list-dis__item,
	.list-num__item {
		&:not(:first-child) {
			@apply mt-4;
		}
	}
}

.list-dis__item {
	&::before {
		@apply absolute;

		top: 6px;
		width: 10px;
		height: 10px;
		background-image: resolve('icon_list_cir.svg');
		background-size: 10px 10px;
		background-repeat: no-repeat;
		content: '';
	}
}

@screen <md {
	.list-dis__item {
		padding-left: 22px;

		&::before {
			left: 0;
		}
	}
}

@screen >=md {
	.list-dis__item {
		padding-left: 18px;

		&::before {
			top: 6px;
			left: 2px;
		}
	}
}

.list-num__item {
	@apply flex;

	&::before {
		@apply font-bold;

		padding-right: 20px;
		content: counter(li) '.';
		counter-increment: li;
		color: var(--font-color);
	}
}

@screen <md {
	.list-num__item {
		text-indent: -22px;
		padding-left: 22px;
	}
}

@screen >=md {
	.list-num__item {
		text-indent: -18px;
		padding-left: 18px;
	}
}
