.link-fit {
	.link__btn {
		@apply max-w-fit;
	}
}

.link-full {
	.link__btn {
		@apply w-full;
	}
}

.link__btn {
	@apply box-border relative items-center flex overflow-hidden;

	background-color: var(--base-darkest-color);
	color: var(--font-light-color);
	font-weight: 500;
	transition: opacity 0.3s var(--transition_02);

	&:visited {
		color: var(--font-color);
	}

	&[href^='http'] {
		@apply relative pr-6;

		&::after {
			@apply absolute h-16 w-1/3;

			bottom: -2px;
			right: 5px;
			transform: translateY(-50%);
			background-position: right 0 center;
			content: '';
		}
	}
}

.link__text {
	@apply w-full py-6 pl-6 pr-12 tracking-widest;

	&::after {
		@apply icon-bg;
	}
}

a.link__text:not([href^='http'])
/* a.col-link-m-block:not([href^='http']).link-fit-wrapper::after,
a.mod-08-block:not([href^='http']) .col-link-m-wrapper::after,
a.col-link-m-block:not([href^='http']) .col-link-m-wrapper::after */ {
	&::after {
		@apply icon-link-in-w;
	}
}

a.link__text[href^='http']
/* a.col-link-m-block[href^='http'].link-fit-wrapper::after,
a.mod-08-block[href^='http'] .col-link-m-wrapper::after,
a.col-link-m-block[href^='http'] .col-link-m-wrapper::after */ {
	&::after {
		@apply icon-link-ex-w;
	}
}
