.gtm {
	@apply box-border absolute z-popup translate-y-0 rounded-md;

	min-width: 160px;
	background-color: var(--base-white-color);
	opacity: 1;
	transition: visibility 0.48s var(--transition_03),
		opacity 0.48s var(--transition_03), transform 0.48s var(--transition_03);

	&.is-hide {
		@apply opacity-0 invisible;

		transform: translateY(10px);
	}
}

@screen <md {
	.gtm {
		@apply bottom-4 right-4 mx-auto p-8;

		max-width: 70%;
	}
}

@screen >=md {
	.gtm {
		@apply bottom-6 right-6 p-8;

		max-width: 500px;
	}
}

.gtm__text {
	@apply tracking-wider text-s2;

	color: var(--base-dark-color);
	font-weight: 400;
}

.gtm__link {
	@apply inline-block underline;

	color: var(--font-link-color);
	font-weight: 400;
}

.gtm__btn {
	@apply inline-block mt-4 mr-4 font-normal text-s2 rounded-md;

	height: 48px;
	transition: opacity 0.35s var(--transition_03);

	&:hover {
		@apply opacity-80;
	}

	&.accept {
		@apply rounded-sm;

		color: var(--font-light-color);
		background-color: var(--base-dark-color);
	}

	&.decline {
		@apply rounded-sm bg-gray-200;
	}
}

@screen <md {
	.gtm__btn {
		min-width: 86px;
		letter-spacing: 1px;
	}
}

@screen >=md {
	.gtm__btn {
		min-width: 130px;
		letter-spacing: 2px;
	}
}
