.toggle__item {
	@apply relative;

	&:not(:first-child) {
		@apply mt-12;
	}
}

.toggle__title {
	@apply relative overflow-hidden;

	z-index: 2;
	background-color: var(--base-light-color);
	color: var(--font-color);
	font-weight: 600;
	transition: background-color 0.3s var(--transition_02);
	will-change: background-color;

	&::before,
	&::after {
		@apply absolute;
		z-index: 2;
		background-color: var(--font-color);
		content: '';
		will-change: transform;
	}

	&::after {
		transform: translateY(50%);
		transition: transform 0.3s var(--transition_02);
		width: 2px;
		height: 18px;
		bottom: 50%;
		left: 28px;
	}

	&::before {
		width: 18px;
		height: 2px;
		bottom: calc(50% - 1px);
		left: 20px;
	}

	.toggle__item.is-visible & {
		&::after {
			transform: translateY(50%) rotate(0) rotate(90deg);
			transition: transform 0.3s var(--transition_02);
		}

		&::before {
			opacity: 0;
			transform: translateY(50%) rotate(0) rotate(90deg);
			transition: transform 0.3s var(--transition_02),
				opacity 0.3s var(--transition_02);
		}
	}
}

@screen <md {
	.toggle__title {
		@apply py-5 pr-8 pl-14;
	}
}
@screen >=md {
	.toggle__title {
		@apply py-6 pr-8 pl-14;
	}
}

.toggle__text {
	@apply relative overflow-hidden max-h-0;

	background-color: var(--base-lightest-color);
	transition: max-height 0.3s var(--transition_01);

	&::after {
		@apply absolute h-px w-full top-0 left-0 opacity-0;

		content: '';
		transition: opacity 1s var(--transition_01);
	}

	.txt-m {
		@apply p-8 opacity-0;

		transition: opacity 1s var(--transition_01);
		will-change: opacity;
	}

	.toggle__item.is-visible & {
		&::after {
			@apply opacity-100;
		}

		.txt-m {
			@apply opacity-100;
		}
	}
}
