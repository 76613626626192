.card-3 {
	@apply antialiased;
}

@screen >=md {
	.card-3 {
		@apply grid grid-cols-3 gap-20;
	}
}

.card__item {
	@apply flex flex-col flex-wrap items-start;
}

@screen <md {
	.card__item {
		&:nth-child(n + 2) {
			@apply mt-10;
		}
	}
}

.card__item__media {
	@apply h-60 w-full overflow-hidden;
}

.card__media__image {
	@apply h-full w-full object-cover origin-center;

	/* filter: grayscale(100%); */
}

.card__description {
	@apply box-border w-full pt-8;
}

.card__description-wrap {
	@apply flex flex-wrap items-center;
}

.card__label {
	@apply box-border inline-block mr-3 py-1 px-2  rounded-sm uppercase text-s4 tracking-widest;

	color: var(--font-light-color);
	background-color: var(--base-dark-color);
	font-weight: 600;
	line-height: 1.2;
}

.card__date {
	@apply text-s2 uppercase;

	color: var(--font-color);
	font-weight: 500;
	line-height: 1.2;
}

.card__title {
	@apply mt-4 text-s1;

	color: var(--base-darkest-color);
	font-weight: 500;
}

.card__rate {
	@apply mt-4;
}

.card__tag {
	@apply mt-6;
}

.card__tag__item {
	@apply inline-block mb-4 mr-4 px-3 py-2 rounded-sm uppercase text-s3 tracking-widest;

	border: 1px solid var(--base-dark-color);
}

.icon__star {
	@apply h-4 w-4 fill-current;
}
