.videos {
	background-color: var(--isabelline);
}

@screen <md {
	.videos {
		@apply py-16;
	}
}

@screen >=md {
	.videos {
		@apply py-32;
	}
}

.videos__wrap {
	@apply mt-10;
}

@screen >=md {
	.videos__wrap {
		@apply flex;
	}
}

@screen sp-land {
	.videos__wrap {
		@apply flex flex-col;
	}
}

.videos__title {
	@apply text-center;
}

/* YouTube */
.youtube {
	@apply flex items-center;

	width: 100%;
	height: 100%;
	/* line-height: 0; */
	pointer-events: visible;
}

@screen <md {
	.youtube {
		width: 100%;
		padding-top: 56.25%;
	}
}

.youtube__player {
	@apply relative z-bg;

	height: 100%;
}

@screen <md {
	.youtube__player {
		top: 0;
		left: 0;
		width: 100%;
		margin: 0 auto;
		position: absolute;
	}
}

.youtube__thumb::after .youtube__thumb.youtube__thumb__none::after {
	display: none;
}

.youtube__thumb {
	@apply absolute top-0 left-0 w-full h-full;

	&__image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--font-light-color);
	}

	&::after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		/* background-color: var(--font-light-color); */
		background-image: resolve('icon_arrow_play.svg');
		background-position: center center;
		background-size: 10px 15px;
		background-repeat: no-repeat;
		border: 2px solid var(--font-light-color);
		border-radius: 50%;
		content: '';

		width: 80px;
		height: 80px;

		&.youtube__thumb__none {
			&::after {
				display: none;
			}
		}
	}
}

.videos__media {
	position: relative;
	z-index: 2;
	overflow: hidden;
	cursor: pointer;
	background-color: var(--black);
}

@screen <md {
	.videos__media {
		@apply w-full;

		/* min-height: 180px; */
	}
}

@screen >=md {
	.videos__media {
		@apply w-3/4;

		/* height: calc(60% * 9 / 16); */
	}
}

@screen sp-land {
	.videos__media {
		@apply w-full;
	}
}

.playlist {
	z-index: 3;
	background-color: var(--base-dark-color);
	color: var(--font-light-color);
	opacity: 0;
	transition: opacity 0.4s var(--transition_01);

	&.loaded {
		opacity: 1;
	}

	.is-ie & {
		display: none;
	}
}

@screen <md {
	.playlist {
		@apply w-full;

		min-height: 450px;
	}
}

@screen >=md {
	.playlist {
		@apply w-1/4;

		min-height: 572px;
	}
}

@screen sp-land {
	.playlist {
		@apply w-full;

		min-height: 450px;
	}
}

.playlist__title {
	@apply flex items-center justify-center text-s1;

	background-color: var(--black);

	.icon-playlist {
		@apply inline-block;

		width: 20px;
		height: 14px;

		.icon-playlist__line {
			fill: none;
			stroke: var(--white);
			stroke-linecap: square;
			stroke-miterlimit: 10;
		}
		.icon-playlist__arrow {
			fill: var(--white);
		}
	}

	.en {
		@apply block ml-2;

		/* padding-left: 30px;

		&::before {
			@apply icon-bg icon-playlist;
		} */
	}
}

@screen <md {
	.playlist__title {
		height: 80px;
	}
}

@screen >=md {
	.playlist__title {
		height: 140px;
	}
}

@screen <md {
	.playlist__list {
		@apply p-6;
	}
}

@screen >=md {
	.playlist__list {
		@apply py-8 px-6;
	}
}

@screen sp-land {
	.playlist__title {
		height: 80px;
	}
}

.playlist__item {
	@apply flex cursor-pointer;

	opacity: 0.6;
	transition: opacity 0.3s ease;

	&:hover,
	&.is-active {
		@apply opacity-100;
	}
}

@screen <md {
	.playlist__item {
		&:nth-child(n + 2) {
			margin-top: 1rem;
		}
	}
}

@screen >=md {
	.playlist__item {
		&:nth-child(n + 2) {
			margin-top: 2rem;
		}
	}
}

.playlist__media {
	@apply overflow-hidden;

	width: 100px;
}

@screen <md {
	.playlist__media {
		/* @apply w-2/6; */

		height: 50px;
	}
}

@screen >=md {
	.playlist__media {
		/* @apply w-2/6; */

		height: 3rem;
	}
}

@screen sp-land {
	.playlist__media {
		/* width: 100px; */
		height: 50px;
	}
}

.playlist__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.playlist__description {
	@apply ml-4;

	width: calc(100% - 100px);
}

.playlist__text {
	@apply text-s3 uppercase;
}

.playlist__update {
	@apply hidden;
}
