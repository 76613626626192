.acc__item {
	@apply relative;
}

.acc__title {
	@apply relative pl-10 cursor-pointer;

	-webkit-tap-highlight-color: transparent;
}

.acc__btn {
	@apply absolute left-0 flex items-center justify-center;

	background-color: var(--royal-blue);
	border-radius: 50%;
	border: 1px solid var(--isabelline);
	transition: background-color 0.3s var(--transition_03),
		transform 0.3s var(--transition_03);

	.acc__item.is-active:hover &,
	.acc__item.is-active & {
		background-color: var(--white);
		border: none;
	}

	.acc__item:not(.is-active):hover & {
		transform: rotate(180deg);
	}
}

@screen <md {
	.acc__btn {
		top: 8px;
		width: 25px;
		height: 25px;
	}
}

@screen >=md {
	.acc__btn {
		top: 28px;
		width: 30px;
		height: 30px;
	}
}

@screen sp-land {
	.acc__btn {
		top: 15px;
	}
}

.acc__btn__icon {
	@apply icon-toggle;

	&::before,
	&::after {
		background-color: var(--white);
	}

	.acc__item.is-active:hover &,
	.acc__item.is-active & {
		@apply icon-toggle-active;

		&::before,
		&::after {
			background-color: var(--royal-blue);
		}
	}
}

.acc__content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.48s var(--transition_01);
	/* transition: max-height 0.3s var(--transition_01); */

	.acc__item.is-active & {
		.nav__modal__item {
			opacity: 1;
		}
	}
}
