.action {
	background: var(--blue-grad);
}

.action__wrap {
}

@screen <md {
	.action__wrap {
		@apply py-20;
	}
}

@screen >=md {
	.action__wrap {
		@apply flex py-32;
	}
}

.action__description {
	@apply relative flex flex-col justify-center;
}

@screen <md {
	.action__description {
		@apply w-full;
	}
}

@screen >=md {
	.action__description {
		width: 50%;
	}
}

.action__description__bg {
	@apply absolute z-bg top-0;

	background: var(--blue-light-grad);
}

@screen <md {
	.action__description__bg {
		@apply -left-10 -top-5;

		width: 150px;
		height: 150px;
	}
}

@screen >=md {
	.action__description__bg {
		@apply -left-20 -top-20;

		width: 230px;
		height: 230px;
	}
}

.action__title {
	@apply relative z-content;

	color: var(--white-gold);

	.ja {
		@apply title3-ja inline-block mt-4;

		font-family: var(--font-sans-ja), sans-serif;
		font-weight: 600;
	}

	.en {
		@apply relative action-title-en uppercase;

		font-family: var(--font-serif-en), serif;
		line-height: 0.9;
	}
}

@screen <md {
	.action__title {
		@apply mt-10;
	}
}

.action__text {
	@apply relative z-content;

	color: var(--white);
}

@screen <md {
	.action__text {
		/* @apply ml-10; */

		.sec__text {
			&:first-child {
				@apply mt-10;
			}

			&:nth-child(n + 2) {
				@apply mt-4;
			}
		}
	}
}

@screen >=md {
	.action__text {
		/* @apply ml-20; */

		.sec__text {
			&:first-child {
				@apply mt-16;
			}

			&:nth-child(n + 2) {
				@apply mt-8;
			}
		}
	}
}

@screen sp-land {
	.action__text {
		@apply ml-0;
	}
}

.action__image {
	@apply flip-image;
}

@screen <md {
	.action__menu {
		@apply pt-16 w-full;
	}
}

@screen >=md {
	.action__menu {
		@apply py-10;

		width: 50%;
	}
}

.action__menu__item {
	color: var(--white);

	&:hover {
		color: var(--white-gold);
	}
}

.action__cont {
	@apply flip-cont;
}

.action__cont__wrap {
	@apply relative flex h-full;
}

.action__cont__bg {
	@apply flip-cont-bg;

	background: var(--blue-grad);
}

.action__cont__title-overlay {
	@apply absolute left-0 action-title-overlay uppercase mix-blend-overlay invisible;

	color: var(--white-gold);
	font-family: var(--font-serif-ja), serif;
	font-weight: 400;
	line-height: 1;
}

@screen <md {
	.action__cont__title-overlay {
		@apply z-bg top-4;
	}
}

@screen >=md {
	.action__cont__title-overlay {
		@apply z-overlap -bottom-6;
	}
}

.action__cont__items {
	@apply w-full;
}

.action__cont__item {
	@apply flex h-full flip-cont-item;

	/* &:nth-child(n + 2) {
		@apply hidden;
	} */
}

@screen <md {
	.action__cont__item {
		@apply flex-col w-full;
	}
}

@screen >=md {
	.action__cont__item {
		width: calc(100% - 120px);
	}
}

.action__cont__description {
	@apply p-20;
}

@screen <md {
	.action__cont__description {
		@apply w-full p-10;
	}
}

@screen >=md {
	.action__cont__description {
		width: 70%;
	}
}

.action__cont__title {
	@apply invisible;

	.en {
		color: var(--white);
	}

	.ja {
		color: var(--white);
	}
}

.action__cont__text {
	@apply mt-10;

	color: var(--white);
	/* @apply flip-cont-text; */
}

.action__cont__media {
	@apply h-full overflow-hidden;

	img {
		@apply flip-image;
	}
}

@screen <md {
	.action__cont__media {
		@apply w-full;
	}
}

@screen >=md {
	.action__cont__media {
		width: 30%;
	}
}

.action__cont__nav {
	@apply cont__nav;
}

.action__cont__nav__menu {
	@apply cont__nav__menu;
}

.action__cont__nav__item {
	@apply cont__nav__item;

	&.is-current {
		background-color: var(--base-dark-color);
	}
}

.action__flip-close {
	@apply icon-close;

	background-color: var(--cardinal-red);

	.icon-close__line {
		background-color: var(--white);
	}

	.icon-close__text {
		color: var(--white);
	}
}

.action__view-btn {
	@apply btn-view;
}
