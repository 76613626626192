.footer {
	@apply w-full;
}

@screen <md {
	.footer {
		.sub &,
		.article &,
		.info & {
			@apply mt-20;
		}
	}
}

@screen >=md {
	.footer {
		.sub &,
		.article &,
		.info & {
			@apply mt-40;
		}
	}
}

.footer-col {
	@apply wrap-full;

	background-color: var(--black);
}

@screen <md {
	.footer-col {
		@apply py-10;
	}
}

@screen >=md {
	.footer-col {
		@apply py-10;
	}
}

.footer-col__link {
	@apply relative;

	color: var(--white-gold);
	border-bottom: 1px solid var(--white-gold);

	.en {
		@apply footer-col-en uppercase;

		font-family: var(--font-serif-en), serif;
	}

	.ja {
		@apply title3-ja;

		font-family: var(--font-sans-jp), sans-serif;
		font-weight: 500;
		line-height: 1.6;
	}

	.btn {
		@apply absolute right-0 bottom-2 ml-auto section-link-txt uppercase;

		padding-right: 30px;
		font-family: var(--font-sans-en), sans-serif;

		&::after {
			@apply icon-bg icon-link-ex-w;

			right: 0;
		}
	}
}

@screen <md {
	.footer-col__link {
		@apply py-2;

		.en {
			@apply block;

			line-height: 1;
		}

		.ja {
			@apply block title4-ja;
		}

		&:nth-child(n + 2) {
			.en {
				@apply mt-6;
			}
		}
	}
}

@screen >=md {
	.footer-col__link {
		@apply flex items-end mb-10 pt-10 pb-10;

		.en {
			line-height: 0.8;
		}

		.ja {
			@apply title3-ja;
		}
	}
}

.footer__wrap {
	@apply relative box-border flex flex-col justify-between w-full wrap-full;
}

@screen <md {
	.footer__wrap {
		@apply h-full py-6;
	}
}

@screen >=md {
	.footer__wrap {
		@apply py-10;
	}
}

.footer__bg {
	@apply absolute z-bg inset-0 w-full h-full;
}

.footer__bg__grad {
	@apply absolute inset-0 w-full h-full opacity-90;

	background: var(--black-grad);
}

@screen <md {
	.footer__menu {
		@apply hidden;
	}
}

@screen >=md {
	.footer__menu {
		@apply flex justify-between mt-20;
	}
}

.footer__top,
.footer__middle,
.footer__bottom {
	@apply relative z-content;
}

@screen <md {
	.footer__top {
		@apply mt-6;
	}

	.footer__middle,
	.footer__bottom {
		@apply mt-10;
	}
}

@screen >=md {
	.footer__top {
		@apply mt-10;
	}

	.footer__middle {
		@apply mt-20;
	}

	.footer__bottom {
		@apply flex justify-between items-end mt-10;
	}
}

@screen <md {
	.footer__menu__list {
		@apply hidden;
	}
}

@screen >=md {
	.footer__menu__item {
		@apply relative;

		&:first-child {
			@apply mb-4 footer-txt-ja;

			font-family: var(--font-serif-en), var(--font-serif-ja), serif;
			font-weight: 400;
		}

		&:nth-child(n + 2) {
			@apply footer-txt2-ja;

			padding-left: 20px;

			&::before {
				@apply absolute top-1/2 left-0;

				width: 8px;
				height: 1px;
				background-color: var(--gold);
				content: '';
				transform: translateY(-50%);
			}
		}

		.footer__menu__item-last {
			@apply relative;
			@apply mb-4 footer-txt-ja;

			font-family: var(--font-serif-en), var(--font-serif-ja), serif;
			font-weight: 400;
		}
	}

	.footer__menu__link {
		@apply inline-block mr-6 py-2 opacity-100;

		color: var(--white);
		transition: opacity 0.56s var(--transition_03);

		.en {
			@apply block mt-2 uppercase footer-txt-en;

			color: var(--gold);
			font-family: var(--font-sans-en), sans-serif;
		}

		&:hover {
			@apply opacity-50;
		}
	}
}

.footer__mark {
	@apply flex;
}

.footer__mark__item {
	&:nth-child(n + 2) {
		@apply ml-6;
	}
}

.footer__mark__link {
	@apply p-3 rounded-md;

	background-color: var(--white);
}

.footer__mark__image {
	/* width: 60px; */
	object-fit: contain;
	height: 60px;
}

@screen <md {
	.footer__browser {
		@apply hidden;
	}
}

@screen >=md {
	.footer__browser {
		@apply flex;
	}
}

.footer__browser__title {
	@apply leading-4 uppercase footer-txt-en;

	color: var(--gold);
	font-weight: 600;
}

.footer__browser__col {
	@apply flex ml-4;
}

.footer__browser__text {
	@apply flex ml-4;
}

.footer__browser__text,
.footer__copyright {
	@apply leading-4 footer-txt-en;

	color: var(--gold);
}

.footer__copyright {
	@apply inline-block;
}

@screen <md {
	.footer__notes {
		@apply flex justify-between;
	}
}

.footer__notes__link {
	@apply inline-block ml-2 leading-4 footer-txt-en;

	color: var(--gold);
}

@screen <md {
	.footer__notes__link {
		@apply block;
	}
}

@screen >=md {
	.footer__notes__link {
		transition: opacity 0.56s var(--transition_03);

		&:hover {
			@apply opacity-50;
		}
	}
}

.footer__notes__text {
	@apply leading-4 footer-txt-en;

	color: var(--gold);
}

.footer__image {
	@apply w-full h-full object-bottom;
}

@screen <md {
	.footer__image {
		@apply object-cover;
	}
}

@screen >=md {
	.footer__image {
		@apply object-cover;
	}
}

.footer__media {
	@apply flex justify-between;
}

.footer__media__link {
	@apply box-border relative opacity-100;
}

@screen <md {
	.footer__media__link {
		&.footer-logo {
			width: 114px;
			height: 34px;
		}

		&.footer-motto {
			width: 138px;
			height: 48px;
		}
	}
}

@screen >=md {
	.footer__media__link {
		&.footer-logo {
			width: 177px;
			height: 53px;
		}

		&.footer-motto {
			width: 138px;
			height: 48px;
		}
	}
}

.footer__media__text {
	@apply footer-txt2-ja mt-4;

	color: var(--white);
}

.footer__media__image {
	@apply h-full w-full object-contain;
}

.footer__notes__media {
	width: 16rem;
	height: 3.1rem;
	margin-top: 0.5rem;
}

.footer__notes__image {
	@apply w-full object-fill;
}

/*
 .footer__pagetop__btn,
 .footer__prev__btn {
   @include footer-btn-text;

   box-sizing: border-box;
   position: fixed;
   bottom: 0;
   font-weight: bold;
   cursor: pointer;
   text-align: center;
   opacity: 1;
   z-index: z('footer-btn');
   transform: translateY(100%);
   background-position: center center;
   background-repeat: no-repeat;

   @include media('<tablet') {
     width: $footer_btn-size-sp;
     height: $footer_btn-size-sp;
     padding-top: 30px;
     transition: transform 1s $transition_01;
   }

   @include media('>=tablet') {
     width: $footer_btn-size-pc;
     height: $footer_btn-size-pc;
     padding-top: 35px;
     transition: opacity 1s $transition_01, transform 1s $transition_01;
      z-index: 98;

     &:hover {
       opacity: 0.8;
       transition: opacity 1s $transition_01;
     }
   }
 }

 .footer__pagetop__btn {
   color: $footer_top_btn_text;
   right: 0;
   background-color: $footer_top_btn_bg;
   background-image: url($ROOT_PATH + '/assets/img/shared/icon-top.svg');

   @include media('<tablet') {
     background-size: 20px 12px;
   }

   @include media('>=tablet') {
     background-size: 25px 15px;
   }
 }

 .footer__prev__btn {
   color: $footer_prev_btn_text;
   background-color: $footer_prev_btn_bg;
   background-image: url($ROOT_PATH + '/assets/img/shared/icon-prev.svg');

   @include media('<tablet') {
     right: $footer_btn-size-sp;
     background-size: 12px 20px;
   }

   @include media('>=tablet') {
     right: $footer_btn-size-pc;
     background-size: 15px 25px;
   }
 } */
