.information {
	background-color: var(--isabelline);
}

.information__wrap {
}

@screen <md {
	.information {
		@apply py-20;
	}
}

@screen >=md {
	.information {
		@apply py-32;
	}
}

.information__col {
	@apply relative;
}

@screen <md {
	.information__col {
		@apply mt-10;
	}
}

@screen >=md {
	.information__col {
		@apply mt-12;
	}
}

.footer__media {
	@apply w-full;
}

.information__nav {
	@apply flex;

	.item__num {
		font-size: 60%;
	}
}

@screen <md {
	.information__nav {
		@apply flex-col;
	}
}

@screen >=md {
	.information__nav {
	}
}

@screen <md {
	.information__tabs {
		@apply grid grid-cols-3 gap-4 mt-4;

		order: 2;
	}
}

.information__tab {
	@apply relative px-4 text-center section-link-txt;

	line-height: 48px;
	height: 48px;
	color: var(--royal-blue);
	border: 1px solid var(--royal-blue);
	transition: background-color 0.3s var(--transition_03),
		color 0.3s var(--transition_03);

	&.is-active,
	&:hover {
		background-color: var(--royal-blue);
		color: var(--white);
	}

	&.is-active {
		&::after {
			@apply absolute left-1/2 w-0 h-0;

			bottom: -8px;
			border-style: solid;
			border-width: 8px 8px 0 8px;
			border-color: var(--royal-blue) transparent transparent transparent;
			content: '';
			transform: translateX(-50%);
		}
	}

	&:nth-child(n + 2) {
		@apply relative;

		&::before {
			@apply absolute top-1/2 left-0;

			width: 1px;
			height: 100%;
			content: '';
			transform: translateY(-50%);
		}
	}
}

@screen >=md {
	.information__tab {
		@apply mr-4;

		min-width: 160px;
		max-width: 200px;
	}
}

.information__links {
	@apply justify-between;
}

@screen <md {
	.information__links {
		@apply grid grid-cols-2 gap-4;

		order: 1;
	}

	.information__tab {
		@apply mr-4;

		margin-right: 0;
		padding-right: 0;
		padding-left: 0;
	}
}

@screen >=md {
	.information__links {
		@apply flex ml-auto;
	}
}

.information__sns {
	@apply flex;

	.icon-fb {
		fill: var(--cardinal-red);
	}

	.icon-tw {
		fill: var(--cardinal-red);
	}

	.icon-yt {
		fill: var(--cardinal-red);
	}

	.sns__item__link {
		width: 48px;
		height: 48px;
	}
}

@screen <md {
	.information__sns {
		order: 2;
	}
}

@screen >=md {
	.information__sns {
		@apply justify-center;
	}
}

.information__more {
	@apply relative pl-4 text-center uppercase section-link-txt;

	line-height: 48px;
	background: var(--cardinal-red);
	color: var(--white);

	&::after {
		@apply icon-bg icon-info;
	}
}

@screen <md {
	.information__more {
		order: 1;
	}
}

@screen >=md {
	.information__more {
		@apply ml-4;
		width: 200px;
	}
}

@screen <md {
	.information__conts {
		@apply mt-10;
	}
}

@screen >=md {
	.information__conts {
		@apply mt-12;
	}
}

.information__cont {
	@apply gap-8;
}

@screen <sm {
	.information__cont {
		@apply grid-cols-1;
	}
}

@screen sm {
	.information__cont {
		@apply grid-cols-2;
	}
}

@screen md {
	.information__cont {
		@apply grid-cols-2;
	}
}

@screen >=lg {
	.information__cont {
		@apply grid-cols-4;
	}
}

.information__item {
	@apply relative px-6;

	border-bottom: 2px solid var(--royal-blue);
	-webkit-tap-highlight-color: transparent;

	&::before {
		@apply absolute z-bg inset-0 w-full h-full;

		background-color: var(--white);
		content: '';
		transition: background-color 0.2s var(--transition_03);
	}

	&:hover {
		&::before {
			background-color: var(--royal-blue);
		}
	}
}

@screen <md {
	.information__item {
		@apply py-6;
	}
}

@screen >=md {
	.information__item {
		@apply z-bg py-8 cursor-pointer;

		&:hover {
			color: var(--white);
		}
	}
}

.information__description {
	@apply relative z-content overflow-hidden;
}

/* .information__date {
	@apply mt-3;
} */

.information__title {
	/* @apply text-s1; */
	@apply title5-ja;
}

@screen <md {
	.information__title,
	.information__tag {
		@apply mt-4;
	}

	.information__title {
		line-height: 1.77;
	}
}

@screen >=md {
	.information__title,
	.information__tag {
		@apply mt-6;
	}

	.information__title {
		line-height: 1.7;
	}
}

.information__tag__item {
	@apply inline-block mb-3 mr-3 py-1 px-2 text-s3;

	background-color: var(--light-gray);
}

@screen >=md {
	.information__tag__item {
		.information__item:hover & {
			color: var(--black);
		}
	}
}

.date-day {
	@apply inline-block text-day;

	font-family: var(--font-num), serif;
	font-weight: 400;
	line-height: 1.5;
}

.date-month {
	@apply text-month ml-1;

	font-family: var(--font-sans-en), sans-serif;
	font-weight: 400;
	line-height: 1.2;
}

.information-modal {
	@apply fixed z-modal inset-0 flex items-center justify-center invisible;
}

.information-modal__cont {
	@apply relative z-content h-full;

	max-height: 80vh;
	background-color: var(--white);
}

@screen <md {
	.information-modal__cont {
		@apply w-5/6;

		padding: 5vh 4vh;
	}
}

@screen >=md {
	.information-modal__cont {
		@apply w-4/6;

		padding: 10vh 8vh;
	}
}

.information-modal__scrollable {
	@apply w-full;

	overflow-y: auto;
	-webkit-overflow-scrolling: touch;

	iframe {
		@apply h-full;
	}

	img {
		@apply w-full;
	}
}

.information-modal__wrap {
	@apply relative h-full;

	/* iframe {
		@apply absolute inset-0 invisible;

		&.is-current {
			@apply relative visible;
		}
	} */
}

.information-modal__close {
	@apply absolute icon-close;

	width: 48px;
	height: 48px;
}

.information-modal__prev,
.information-modal__next {
	@apply absolute top-1/2 cursor-pointer;

	width: 60px;
	height: 60px;
	background-color: var(--royal-blue);
	border-radius: 50%;
	transform: translateY(-50%);
	-webkit-tap-highlight-color: transparent;

	&:hover {
		transform: translateY(-50%);
	}

	&::after {
		@apply left-1/2 icon-bg icon-link-in-w;

		transform: translate(-50%, -50%) rotate(180deg);
	}
}

.information-modal__prev {
	left: -30px;

	&::after {
		transform: translate(-50%, -50%) rotate(180deg);
	}
}

.information-modal__next {
	right: -30px;

	&::after {
		transform: translate(-50%, -50%);
	}
}

.information-modal__close {
	@apply hidden;
	/* @apply absolute top-6 right-4; */
}
