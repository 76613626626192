table tr th,
table tr td {
	@apply text-s1;
}

.tbl {
	/* @apply w-full; */

	table {
		border-collapse: collapse;
		border-spacing: 0;
		color: var(--font-color);

		thead tr th {
			color: var(--white);

			/* background-color: var(--base-lighter-color); */
		}

		thead tr td {
			@apply text-center;
		}

		tbody tr th {
			color: var(--white);

			/* background-color: var(--base-lighter-color); */
		}

		tbody tr td {
			color: var(--white);

			/* background-color: var(--base-lightest-color); */
		}

		th,
		td {
			@apply box-border p-6 text-s1;
		}

		th {
			letter-spacing: 0.2rem;
		}

		td {
			vertical-align: top;
		}
	}

	.tbl-3 {
	}

	.tbl-3,
	.tbl-4,
	.tbl-2 {
		@apply box-border overflow-x-auto;
	}

	@screen <md {
		table {
			min-width: 749px;
		}
	}

	@screen >=md {
		table {
			@apply w-full;
		}
	}
	/*
	@screen <md {
		.tbl {
			&:not(:first-of-type) {
				@apply mt-16;
			}
		}
	}

	@screen >=md {
		.tbl {
			@apply mt-24;
			main > & {
				@apply box-border;
			}
		}
	} */

	.tbl-3,
	.tbl-4 {
		table {
			table-layout: fixed;
		}

		th,
		td {
			border-collapse: collapse;
		}
	}

	.tbl-4 {
		border-top: 6px solid;

		th {
			border-bottom: 2px solid var(--theme-color);

			&:first-of-type {
				border-right: 2px solid var(--theme-color);
			}
			&:not(:first-of-type):not(:last-of-type) {
				border-right: 2px solid var(--theme-color);
				border-left: 2px solid var(--theme-color);
			}
			&:last-of-type {
				border-left: 2px solid var(--theme-color);
			}
		}

		td {
			border-top: 2px solid var(--theme-color);
			border-bottom: 2px solid var(--theme-color);

			&:first-of-type {
				border-right: 2px solid var(--theme-color);
			}
			&:not(:first-of-type):not(:last-of-type) {
				border-right: 2px solid var(--theme-color);
				border-left: 2px solid var(--theme-color);
			}
			&:last-of-type {
				border-left: 2px solid var(--theme-color);
			}
		}
	}

	.tbl-3 {
		border-left: 6px solid;

		table {
			thead {
				tr {
					th {
						&:first-of-type {
							@apply text-center;

							background-color: var(--base-light-color);
						}

						&:nth-child(n + 2) {
							background-color: var(--base-lighter-color);
						}
					}

					td {
						@apply font-bold;
					}
				}
			}

			th {
				@apply w-1/3;

				border-bottom: 2px solid var(--theme-color);

				&:first-of-type {
					border-right: 2px solid var(--theme-color);
				}
				&:not(:first-of-type):not(:last-of-type) {
					border-right: 2px solid var(--theme-color);
					border-left: 2px solid var(--theme-color);
				}
				&:last-of-type {
					border-left: 2px solid var(--theme-color);
				}
			}

			tr {
				&:nth-of-type(n + 2) {
					border-top: 2px solid var(--theme-color);
				}

				td {
					&:first-of-type {
						border-right: 1px solid var(--theme-color);
					}
					&:not(:first-of-type):not(:last-of-type) {
						border-right: 2px solid var(--theme-color);
						border-left: 2px solid var(--theme-color);
					}
					&:last-of-type {
						border-left: 2px solid var(--theme-color);
					}
				}
			}

			tbody {
				tr {
					td {
						&:first-of-type {
							@apply text-center;

							background-color: var(--base-light-color);
						}
					}
				}

				& > tr {
					&:first-of-type {
						td {
							@apply align-middle;

							letter-spacing: 0.2rem;
						}
					}
				}
			}

			tr:not(:last-child) {
				th {
					border-bottom: 4x solid var(--theme-color);
				}
			}
		}
	}

	.tbl-2 {
		border-top: 1px solid var(--white);
		border-bottom: 1px solid var(--white);
		/* border-left: 6px solid; */

		table {
			thead {
				@apply hidden;
			}

			th {
				@apply w-1/3;

				border-bottom: 1px solid var(--white);

				&:first-of-type {
					border-right: 1px solid var(--white);
				}
				&:not(:first-of-type):not(:last-of-type) {
					border-right: 1px solid var(--white);
					border-left: 1px solid var(--white);
				}
				&:last-of-type {
					border-left: 1px solid var(--white);
				}
			}

			tr {
				&:nth-of-type(n + 2) {
					border-top: 1px solid var(--white);
				}

				td {
					&:first-of-type {
						/* border-right: 1px solid var(--white); */
					}
					&:not(:first-of-type):not(:last-of-type) {
						border-right: 1px solid var(--white);
						border-left: 1px solid var(--white);
					}
					&:last-of-type {
						/* border-left: 1px solid var(--white); */
					}
				}
			}

			tbody {
				tr {
					td {
						&:first-of-type {
							@apply text-left;
							color: var(--white);

							/*
							background-color: var(--base-lightest-color); */
							font-weight: 600;
						}
					}
				}

				& > tr {
					&:first-of-type {
						td {
							@apply align-middle;

							letter-spacing: 0.2rem;
						}
					}
				}
			}

			tr:not(:last-child) {
				th {
					border-bottom: 4x solid var(--white);
				}
			}
		}
	}
	/*
	.tbl-2 {
		border-left: 6px solid;
		border-image: linear-gradient(135deg, #b04ac2 0%, #1f49d3 100%) 1;

		table,
		tbody {
			@apply flex;
		}

		thead {
			width: 30%;
		}

		tbody {
			width: 70%;
		}

		tr,
		td {
			@apply w-full block;

			border-collapse: collapse;
		}

		tr {
			@apply m-0 p-0;
		}

		td {
			@apply m-0;

			border-collapse: collapse;
			border-spacing: 0;
			border-right: 1px solid var(--theme-color);
			border-left: 1px solid var(--theme-color);

			&:first-of-type {
				border-bottom: 1px solid var(--theme-color);
			}
			&:not(:first-of-type):not(:last-of-type) {
				border-top: 1px solid var(--theme-color);
				border-bottom: 1px solid var(--theme-color);
			}
			&:last-of-type {
				border-top: 1px solid var(--theme-color);
			}
		}

		th {
			@apply w-full block;

			border-right: 1px solid var(--theme-color);

			&:first-of-type {
				border-bottom: 1px solid var(--theme-color);
			}
			&:not(:first-of-type):not(:last-of-type) {
				border-top: 1px solid var(--theme-color);
				border-bottom: 1px solid var(--theme-color);
			}
			&:last-of-type {
				border-top: 1px solid var(--theme-color);
			}
		}
	} */
}
