.form {
	@apply w-full;
	background-color: var(--base-lightest-color);
}

@screen <md {
	.form {
		@apply py-10 px-8;
	}
}

@screen >=md {
	.form {
		@apply py-28 px-40;
	}
}

.form__title {
	@apply text-center;

	color: var(--font-color);

	.label {
		@apply block text-s1;

		color: var(--font-color);
		font-weight: 500;
	}

	.en {
		@apply block uppercase text-f1;

		font-weight: 500;
	}

	.ja {
		@apply font-semibold text-f2;
	}
}

.form__item__title {
	@apply relative pr-2 text-s1;

	color: var(--font-color);
	font-weight: 500;

	.required {
		@apply mt-1 text-s2;

		color: var(--font-red-color);
		line-height: 1;
	}
}

@screen <md {
	.form__item__title {
		@apply w-full mb-2;

		.required {
			@apply ml-2;
		}
	}
}

@screen >=md {
	.form__item__title {
		width: 30%;

		.required {
			@apply block;
		}
	}
}

.form__add__item__title,
.form__add__item__mark {
	@apply text-s2;

	color: var(--font-color);
}

.form__add__item__title {
	@apply mt-6 mb-2 pr-2;
}

.form__add__item__mark {
	@apply block mx-2 text-center;

	color: var(--font-color);
}

.postal-code {
	@apply flex items-center;
}

@screen <md {
	.form__item {
		&:nth-of-type(n + 2) {
			@apply mt-8;
		}
	}
}

@screen >=md {
	.form__item {
		@apply flex;

		&:nth-of-type(n + 2) {
			@apply mt-10;
		}
	}
}

.form__item__label {
	@apply relative flex items-center mr-8;
}

.form__required {
	color: var(--neon-pink);
}

.form__item__radio {
	@apply absolute top-0 left-0 opacity-0;
}

.form__item__radio__text {
	@apply tracking-wider;

	line-height: 18px;
	color: var(--font-color);
}

.form__item__radio__icon {
	@apply relative cursor-pointer;

	margin: 0;
	padding: 0 0 0 26px;
	-webkit-tap-highlight-color: transparent;

	&::before {
		@apply block absolute top-0 left-0 rounded-full;

		height: 18px;
		width: 18px;
		background-color: var(--base-dark-color);
		content: '';
	}

	&::after {
		@apply absolute appearance-none rounded-full opacity-0;

		width: 18px;
		height: 18px;
		top: 0;
		left: 0;
		border: 2px solid var(--base-dark-color);
		content: '';
		transition: opacity 0.2s var(--transition_03);
	}
}

.form__item__radio:checked + .form__item__radio__icon::after {
	opacity: 1;
}

.form__add__item__text,
.form__text-s,
.form__text {
	@apply p-3 appearance-none;

	border-radius: 0;
	background-color: var(--base-lighter-color);
	border: 2px solid var(--base-lighter-color);
	color: var(--gray);
	transition: border 0.2s var(--transition_03);

	&:focus {
		border: 2px solid var(--base-dark-color);
		color: var(--font-color);
		box-shadow: none;
		outline: none;
	}
}

.form__add__item__text,
.form__text-s,
.form__text {
	@apply text-s1;
}

.form__text-s {
	width: 150px;
}

@screen <md {
	.form__add__item,
	.form__text {
		@apply w-full;
	}
}

@screen >=md {
	.form__add__item,
	.form__text {
		width: 70%;
	}
}

.form__add__item__text {
	@apply w-full;
}

/* Select */
.form__select-wrap {
	position: relative;
	width: 150px;
	&::after {
		color: var(--font-color);
		content: '▾';
		margin-right: 10px;
		pointer-events: none;
		position: absolute;
		right: 10px;
		top: 15px;
		font-size: 20px;
	}
}

.form__select {
	@apply w-full p-4 appearance-none text-s1;

	background: var(--base-lighter-color);
	border: 2px solid var(--base-lighter-color);
	border: none;
	border-radius: 0;
	color: var(--font-color);
	cursor: pointer;
	outline: none;
	-webkit-tap-highlight-color: transparent;
	&:focus {
		color: var(--font-color);
	}
}

/* Buttons */
@screen <md {
	.form__btns {
		@apply mt-10;
	}
}

@screen >=md {
	.form__btns {
		@apply mt-20;

		padding-left: 30%;
	}
}

.form__submit {
	@apply box-border rounded-sm cursor-pointer;
	transition: opacity 0.56s var(--transition_03);

	&:hover {
		@apply opacity-80;
	}

	min-width: 250px;
	background-color: var(--base-darkest-color);
	text-align: center;
}

@screen <md {
	.form__submit {
	}
}

@screen >=md {
	.form__submit {
		margin-right: auto;
	}
}

.form__submit__text[type='submit'] {
	@apply text-s1 w-full h-full py-4 px-10 cursor-pointer;

	color: var(--font-light-color);
	font-weight: 500;
}
